import Card from "antd/es/card"
import Skeleton from "antd/es/skeleton"
import { collection } from "firebase/firestore"
import { PlusIcon } from "lucide-react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"

import Header from "../../components/Header"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import {
  PLAYBOOKS_SUBCOLLECTION,
  type Playbook,
} from "../../live/playbook/types"
import { GROUPS_COLLECTION } from "../../types/common"

const PlaybookForm: React.FC = () => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [playbooks, playbooksLoading] = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      PLAYBOOKS_SUBCOLLECTION,
    ).withConverter(makeConverter<Playbook>()),
  )

  return (
    <div className="flex grow items-start p-8">
      <Skeleton loading={playbooksLoading} active>
        <div className="flex flex-wrap gap-2 overflow-y-auto">
          {(playbooks ?? []).map((playbook) => (
            <Link
              to={`/live-assistant/playbooks/${playbook.oid}`}
              key={playbook.oid}
            >
              <Card
                className="mr-4 w-[400px] hover:shadow"
                size="small"
                title={playbook.name}
              >
                {playbook.description}
              </Card>
            </Link>
          ))}
          <Link to="/live-assistant/playbooks/new" className="w-fit">
            <Card
              className="w-[400px] hover:shadow"
              size="small"
              title={
                <div className="flex items-center">
                  <PlusIcon height="20" /> New Playbook
                </div>
              }
            >
              Add a new playbook here
            </Card>
          </Link>
        </div>
      </Skeleton>
    </div>
  )
}

const PlaybooksListPage: React.FC = () => {
  return (
    <>
      <Header
        title="Playbooks"
        breadcrumbs={[
          {
            title: "Live Assistant",
            href: "/live-assistant",
          },
          {
            title: "Playbooks",
            href: "/live-assistant/playbooks",
          },
        ]}
      />
      <PlaybookForm />
    </>
  )
}

export default PlaybooksListPage
