import Button from "antd/es/button"
import { ArrowUpRightIcon } from "lucide-react"

interface Props {
  children?: React.ReactNode
  href: string
  className?: string
  target?: string
}

const ExternalLink: React.FC<Props> = ({
  children,
  href,
  className,
  target = "_blank",
}) => {
  let linkClass = "items-center hover:underline "
  if (className) {
    linkClass += className
  }
  return (
    <a
      className={linkClass}
      href={href}
      target={target}
      rel="noopener noreferrer"
    >
      {children ?? href}
      <ArrowUpRightIcon className="ml-[-0.25em] inline h-3" />
    </a>
  )
}

export const ExternalLinkButton: React.FC<Props> = ({
  children,
  href,
  className,
  target = "_blank",
}) => {
  // Transfer button padding to inner component so the entire component is a clickable link
  return (
    <Button type="primary" className="p-0">
      <a
        className={`py-15 items-center px-4 ${className}`}
        href={href}
        target={target}
        rel="noopener noreferrer"
      >
        {children}
        <ArrowUpRightIcon className="ml-[-0.25em] inline h-3" />
      </a>
    </Button>
  )
}

export default ExternalLink
