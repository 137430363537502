import { httpsCallable } from "../serverUtils"

export interface OauthStateParam {
  code_challenge?: string
  code_challenge_method?: string
  state: string
}

export const getOauthStateParam = httpsCallable<void, OauthStateParam>(
  "getOauthStateParam",
)
