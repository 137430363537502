/**
Models OIDs need to match live_assistant/types/playbook.py
**/
import type { PlaybookCriteriaModel } from "./types"

const CURRENT_SOLUTION: PlaybookCriteriaModel = {
  oid: "current_solution",
  name: "Current Solution / Processes",
  description: "Capturing discussed current solution",
  category: "QUALIFICATION",
}

const COMPETITION: PlaybookCriteriaModel = {
  oid: "competition",
  name: "Competition",
  description: "Capturing discussed competitors",
  category: "QUALIFICATION",
}

const RED_FLAGS: PlaybookCriteriaModel = {
  oid: "red_flags",
  name: "Red Flags / Risks",
  description: "Capturing deal breakers raised",
  category: "MOVING_FORWARD",
}

export const CRITERIA_MODELS = [CURRENT_SOLUTION, COMPETITION, RED_FLAGS]
