import Button from "antd/es/button"
import { CheckIcon, ExternalLink, MinusIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import type { SheetDiscussion } from "../../discussions/types"
import { isVerbatimFromAnswerBank } from "../../hooks/useEnrichedReference"
import useErrorPopup from "../../hooks/useErrorPopup"
import { getColumnNumber } from "../../sheets/utils"
import type { AnswerReference } from "../../types/answerer"
import type { StoredGeneratedAnsweredQuestion } from "../../types/jobs"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import type { WorksheetRange } from "../../types/sheets"
import AnswerFeedbackButtons from "../AnswerFeedbackButtons"
import AssignQuestionnaireAnswerButton from "../AssignQuestionnaireAnswerForm"
import Discussions from "../Discussions/Discussions"
import ReferenceLink from "../ReferenceLink"
import { useGridContext } from "./GridContext"
import { reviewAnswer } from "./utils"

const SURFACE = "WEB_QUESTIONNAIRE_ASSISTANT"

interface Props {
  references?: AnswerReference[]
  activeGroupOid: string
  selectedSheet: string
  job: AnswerQuestionnaireJob
  discussions?: SheetDiscussion[]
  answer?: StoredGeneratedAnsweredQuestion
  discussionsError?: Error
  location: WorksheetRange
}

const CellSidebarPane: React.FC<Props> = ({
  references,
  activeGroupOid,
  selectedSheet,
  job,
  discussions,
  answer,
  discussionsError,
  location,
}) => {
  const { cellFocused, onClickLocation } = useGridContext()
  const { authUser } = useActiveUserAuthorizationFromContext()
  const { handleError, handleSuccess } = useErrorPopup()

  const [changingReviewState, setChangingReviewState] = useState(false)

  const filteredDiscussions = useMemo(() => {
    if (!discussions?.length || !cellFocused) return
    return discussions.filter((discussion) => {
      const range = discussion.location
      return (
        range.sheetName === selectedSheet &&
        getColumnNumber(cellFocused.colId) == range.firstColIndex &&
        cellFocused.rowIndex + 1 == range.firstRowIndex
      )
    })
  }, [cellFocused, discussions, selectedSheet])

  const onChangeReview = useCallback(
    async (kind: "REVIEW" | "UNREVIEW") => {
      if (!answer?.oid) return
      setChangingReviewState(true)
      try {
        await reviewAnswer(kind, job.oid, [answer])
        handleSuccess(
          kind === "REVIEW" ? "Answer approved!" : "Answer unapproved!",
        )
      } catch (error) {
        handleError({ error, prefix: "Couldn't change review status" })
      } finally {
        setChangingReviewState(false)
      }
    },
    [handleSuccess, handleError, answer, job.oid],
  )

  const reviewItem = useCallback(async () => {
    return await onChangeReview("REVIEW")
  }, [onChangeReview])

  const unreviewItem = useCallback(async () => {
    return await onChangeReview("UNREVIEW")
  }, [onChangeReview])

  const canApproveOrTransferOwnership =
    job.creator.uid === authUser.uid ||
    answer?.last_assigned_to?.uid === authUser.uid

  const isReviewed = !!answer?.last_reviewed_by

  return (
    <>
      {references?.length ? (
        <div>
          <h3>
            <span className="mr-1 font-bold text-purple-500">
              {`${cellFocused?.colId}${(cellFocused?.rowIndex ?? 0) + 1}`}
            </span>
            References
          </h3>
          {references.map((r, idx) => (
            <ReferenceLink
              index={idx}
              key={`${r.document_oid}:${r.piece_oid}:${r?.knowledge_item_oid}`}
              reference={r}
              loadEagerly
              isVerbatimFromAnswerBank={isVerbatimFromAnswerBank(references)}
            />
          ))}
        </div>
      ) : answer ? (
        <div className="mt-4">No references to display</div>
      ) : null}

      {answer ? (
        <div>
          <div className="mt-2 flex gap-2">
            <AnswerFeedbackButtons
              surface={SURFACE}
              answer={answer}
              answererRequestId={answer.last_quilt_answer_request_id}
            />
          </div>

          <a
            href={`/questionnaire-assistant/review/feed/${job.oid}?oid=${answer.oid}`}
            target="_blank"
            className="text-primary mt-4 flex items-center gap-2 font-semibold underline"
          >
            <ExternalLink size={16} /> View in feed
          </a>

          {canApproveOrTransferOwnership ? (
            <>
              <h3 className="mb-0 mt-4">Approval</h3>
              <div className="mt-2 flex">
                <AssignQuestionnaireAnswerButton
                  jobOid={job.oid}
                  answerOids={[answer.oid]}
                  assignedTo={answer.last_assigned_to}
                />
                {isReviewed ? (
                  <Button
                    className="submit ml-2 flex items-center"
                    onClick={unreviewItem}
                    icon={<MinusIcon />}
                    title="Remove approval"
                    disabled={changingReviewState}
                  >
                    Unapprove
                  </Button>
                ) : (
                  <Button
                    className="submit ml-2 flex items-center"
                    onClick={reviewItem}
                    type="primary"
                    icon={<CheckIcon />}
                    title="Mark as Approved"
                    disabled={changingReviewState}
                  >
                    {changingReviewState ? "Approving..." : "Approve"}
                  </Button>
                )}
              </div>
            </>
          ) : null}
        </div>
      ) : null}

      <Discussions
        group_oid={activeGroupOid}
        discussions={filteredDiscussions}
        job_id={job.oid}
        location={location}
        kind="SHEET"
        discussionsError={discussionsError}
        onClickLocation={onClickLocation}
      />
    </>
  )
}

export default CellSidebarPane
