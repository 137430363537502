import { Button, Input, Popover, Space } from "antd"
import { PlusIcon, TrashIcon } from "lucide-react"

interface MultipleChoiceEditorProps {
  initialOptions: string[]
  onSave: (options: string[]) => void
}

const MultipleChoiceEditor: React.FC<MultipleChoiceEditorProps> = ({
  initialOptions,
  onSave,
}) => {
  const handleInputChange = (
    index: number,
    value: string,
    currentOptions: string[],
  ) => {
    const newOptions = [...currentOptions]
    newOptions[index] = value
    onSave(newOptions)
  }

  const addOption = (currentOptions: string[]) => {
    onSave([...currentOptions, ""])
  }

  const removeOption = (index: number, currentOptions: string[]) => {
    const newOptions = currentOptions.filter((_, idx) => idx !== index)
    onSave(newOptions)
  }

  return (
    <div className="w-72">
      {initialOptions.length === 0 ? <div>Default: Yes, No</div> : null}
      <Space direction="vertical" className="w-full" size="small">
        {initialOptions.map((option, index) => (
          <Space key={index} className="w-full">
            <Input
              value={option}
              onChange={(e) =>
                handleInputChange(index, e.target.value, initialOptions)
              }
              placeholder={`Option ${index + 1}`}
            />
            <TrashIcon
              size={20}
              className="cursor-pointer text-gray-400 hover:text-red-500"
              onClick={() => removeOption(index, initialOptions)}
            />
          </Space>
        ))}

        <Button
          className="mt-2"
          type="dashed"
          onClick={() => addOption(initialOptions)}
          icon={<PlusIcon />}
        >
          Add Option
        </Button>
      </Space>
    </div>
  )
}

const MultipleChoiceEditorPopover: React.FC<{
  initialOptions: string[]
  onSave: (options: string[]) => void
  sheetIndex: number
}> = ({ initialOptions, onSave, sheetIndex }) => {
  return (
    <Popover
      placement="leftBottom"
      content={
        <MultipleChoiceEditor
          key={`mcq-editor-${sheetIndex}`}
          initialOptions={initialOptions}
          onSave={onSave}
        />
      }
      title="Multiple Choice Options"
      trigger="click"
      destroyTooltipOnHide={true}
    >
      <Button className="ml-2" type="primary">
        Edit
      </Button>
    </Popover>
  )
}

export default MultipleChoiceEditorPopover
