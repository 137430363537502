import type { User as AuthUser } from "firebase/auth"

import type { Group } from "../../groups/types"
import MyInvitesManager from "./MyInvitesManager"
import UnifiedGroupTable from "./UnifiedGroupMembersManager"

const MembersTab: React.FC<{
  activeGroup: Group
  user: AuthUser
}> = ({ user, activeGroup }) => {
  return (
    <div>
      <UnifiedGroupTable group={activeGroup} />
      <MyInvitesManager user={user} />
    </div>
  )
}
export default MembersTab
