import type { MenuProps } from "antd"
import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import { doc, updateDoc } from "firebase/firestore"
import { MoreHorizontal } from "lucide-react"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { confirmAndExecute } from "../../components/confirmAndExecute"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { GROUPS_COLLECTION } from "../../types/common"
import { ANSWER_QUESTIONNAIRE_JOBS_COLLECTION } from "../../types/jobs"
import {
  createAnswerQuestionnaireJobDownloadUrl,
  createSourceDocumentFromQuestionnaire,
  reviewAnswerQuestionnaireJob,
  unmarkCompletedAnswerQuestionnaireJob,
} from "./api"

interface Props {
  groupOid: string
  oid: string
  exportedSourceDocumentOid: string | null
  canReview: boolean
  canDownload: boolean
  isCompleted: boolean
  disabled: boolean
}

const QuestionnaireCardMenu: React.FC<Props> = ({
  groupOid,
  oid,
  exportedSourceDocumentOid,
  canReview,
  canDownload,
  isCompleted,
  disabled,
}) => {
  const { messageApi, handleSuccess, handleError } = useErrorPopup()
  const [modifying, setModifying] = useState(false)
  const [creatingSource, setCreatingSource] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const navigate = useNavigate()

  const onMarkCompleted = useCallback(async () => {
    setModifying(true)
    try {
      await reviewAnswerQuestionnaireJob(oid)
      handleSuccess("Marked questionnaire as completed")
    } catch (error) {
      handleError({ prefix: "Couldn't complete questionnaire", error })
      setModifying(false)
    }
  }, [oid, handleSuccess, handleError])

  const onUnmarkCompleted = useCallback(async () => {
    setModifying(true)
    try {
      await unmarkCompletedAnswerQuestionnaireJob(oid)
      handleSuccess("Marked questionnaire for re-review")
    } catch (error) {
      handleError({ prefix: "Couldn't send questionnaire for review", error })
      setModifying(false)
    }
  }, [oid, handleSuccess, handleError])

  const onRemove = useCallback(async () => {
    if (modifying) {
      return
    }

    const docRef = doc(
      db,
      GROUPS_COLLECTION,
      groupOid,
      ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
      oid,
    )
    setModifying(true)
    try {
      await updateDoc(docRef, { removal_requested: true })
      handleSuccess("Started removing questionnaire")
    } catch (error) {
      handleError({ error, prefix: "Couldn't remove questionnaire" })
    } finally {
      setModifying(false)
    }
  }, [modifying, groupOid, oid, handleSuccess, handleError])

  const onDownload = useCallback(async () => {
    if (modifying) {
      return
    }

    setModifying(true)
    setDownloadLoading(true)
    try {
      void messageApi.info("Preparing questionnaire download")
      const downloadUrl = await createAnswerQuestionnaireJobDownloadUrl(oid)
      void messageApi.success("Downloading questionnaire")
      window.open(downloadUrl, "_blank", "noopener,noreferrer")
    } catch (error) {
      handleError({ error, prefix: "Couldn't download questionnaire" })
    } finally {
      setDownloadLoading(false)
      setModifying(false)
    }
  }, [modifying, oid, messageApi, handleError])

  const clickCreateSource = useCallback(async () => {
    if (creatingSource) {
      return
    }
    try {
      setCreatingSource(true)
      void messageApi.info("Importing...")
      await createSourceDocumentFromQuestionnaire(oid)
      handleSuccess(
        "Imported as source document, Answer Bank will update in the background",
      )
    } catch (error) {
      handleError({ error, prefix: "Couldn't import answers" })
    } finally {
      setCreatingSource(false)
    }
  }, [creatingSource, oid, messageApi, handleSuccess, handleError])

  const items: MenuProps["items"] = []

  if (canReview) {
    items.push({
      label: "Review",
      onClick: () => navigate(`/questionnaire-assistant/review/feed/${oid}`),
      key: "review",
    })
  }

  if (canDownload) {
    items.push({
      label: downloadLoading ? "Downloading..." : "Download",
      onClick: downloadLoading ? undefined : (onDownload as () => void),
      key: "download",
    })
  }

  if (isCompleted) {
    if (exportedSourceDocumentOid) {
      items.push({
        label: "Imported to Answer Bank ↗",
        onClick: () =>
          window.open(
            `/source-documents/${exportedSourceDocumentOid}`,
            "_blank",
          ),
        key: "go-to-source",
      })
    } else {
      items.push({
        label: creatingSource
          ? "Importing..."
          : "Import Answers to Answer Bank",
        onClick: clickCreateSource as () => void,
        disabled: creatingSource,
        key: "create-source",
      })
    }
    items.push({
      label: "Mark for Re-review",
      onClick: onUnmarkCompleted as () => void,
      key: "unmark-complete",
    })
  }

  if (canReview) {
    items.push({
      label: "Mark as Complete",
      onClick: confirmAndExecute(
        onMarkCompleted,
        "Are you sure you want to mark this questionnaire as complete?",
      ),
      key: "mark-complete",
    })
  }

  items.push({
    label: "Remove Questionnaire",
    onClick: confirmAndExecute(
      onRemove,
      "Are you sure you want to remove this questionnaire?",
    ),
    key: "remove",
  })

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button
        type="default"
        className="flex items-center"
        size="small"
        disabled={disabled}
      >
        <MoreHorizontal />
      </Button>
    </Dropdown>
  )
}

export default QuestionnaireCardMenu
