import { Skeleton } from "antd"
import { doc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"

import Header from "../../components/Header"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { CALL_OUTPUTS_SUBCOLLECTION } from "../../live/playbook/db"
import type { CallOutput } from "../../live/playbook/types"
import { GROUPS_COLLECTION } from "../../types/common"
import { toHumanReadableString } from "../../utils"
import EditableOutput from "./EditablePlaybook"
import { StatusBadge } from "./index"

const LoadingInfoGrid = () => (
  <div className="grid grid-cols-2 gap-x-6 gap-y-3">
    {[1, 2, 3, 4].map((index) => (
      <div key={index}>
        <div className="mb-1">
          <Skeleton.Input style={{ width: 100 }} active size="small" />
        </div>
        <Skeleton.Input style={{ width: 150 }} active size="small" />
      </div>
    ))}
  </div>
)

const CallOutputPage = () => {
  const { callOid = "" } = useParams<{ callOid: string }>()
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [callOutput, callOutputLoading, callOutputLoadingError] =
    useDocumentData(
      doc(
        db,
        GROUPS_COLLECTION,
        activeGroupOid,
        CALL_OUTPUTS_SUBCOLLECTION,
        callOid,
      ).withConverter(makeConverter<CallOutput>()),
    )

  if (callOutputLoadingError) {
    return <div>Error loading call</div>
  }

  return (
    <>
      <Header
        title={
          callOutputLoading ? (
            <Skeleton.Input style={{ width: 200 }} active />
          ) : (
            callOid
          )
        }
        breadcrumbs={[
          { title: "Calls", href: "/calls" },
          {
            title: callOid,
            href: `/calls/${callOid}`,
          },
        ]}
      />
      <div className="flex grow flex-col gap-4 p-8">
        <div className="rounded-lg border border-gray-200 bg-white p-6">
          {callOutputLoading ? (
            <LoadingInfoGrid />
          ) : (
            <div className="grid grid-cols-2 gap-x-6 gap-y-3">
              <div>
                <div className="text-sm font-medium text-gray-500">Source</div>
                <div className="capitalize">
                  {callOutput?.source_kind.toLowerCase().replaceAll("_", " ")}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">
                  Created By
                </div>
                <div>{callOutput?.created_by.email}</div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">
                  Created At
                </div>
                <div>
                  {callOutput
                    ? toHumanReadableString(callOutput.created_at, {
                        showTime: true,
                      })
                    : "Unknown"}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Status</div>
                <StatusBadge
                  className={"inline-flex"}
                  isDone={callOutput?.playbook_output?.done_processing}
                />
              </div>
            </div>
          )}
        </div>

        {(callOutputLoading || callOutput?.playbook_output) && (
          <div className="p-4">
            <EditableOutput
              output={callOutput?.playbook_output}
              loading={callOutputLoading}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CallOutputPage
