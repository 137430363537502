import Alert from "antd/es/alert"
import Button from "antd/es/button"
import Form from "antd/es/form"
import Skeleton from "antd/es/skeleton"
import Space from "antd/es/space"
import Switch from "antd/es/switch"
import { doc, setDoc } from "firebase/firestore"
import { useCallback, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"

import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import {
  GROUP_SETTINGS_COLLECTION,
  type Group,
  type GroupSettings,
} from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"

type FormDataType = GroupSettings

interface Props {
  group: Group
}

const docRef = (groupOid: string) =>
  doc(db, GROUP_SETTINGS_COLLECTION, groupOid).withConverter(
    makeConverter<GroupSettings>(),
  )

const createOrUpdateSettings = async (
  groupOid: string,
  settings: Omit<FormDataType, "oid">,
) => {
  await setDoc(docRef(groupOid), settings)
}

const SettingsTab: React.FC<Props> = ({ group }) => {
  const [form] = Form.useForm<FormDataType>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const [settings, loading, error] = useDocumentData(docRef(group.oid))

  const onFinish = useCallback(
    async (formData: FormDataType) => {
      setSubmitting(true)
      try {
        await createOrUpdateSettings(group.oid, formData)
        handleSuccess("Updated settings")
      } catch (error) {
        handleError({ error, prefix: "Couldn't update settings" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, group.oid],
  )

  if (error) {
    return (
      <Alert
        message="Could not load settings"
        showIcon
        description={error.message}
        type="error"
      />
    )
  }

  const initialValues = settings ?? {}
  return (
    <Skeleton active loading={loading}>
      <Form
        form={form}
        layout="vertical"
        className="w-full max-w-3xl"
        disabled={loading || submitting}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <h3>Advanced Settings</h3>
        <Form.Item
          label="Automatically import questionnaire on completion"
          tooltip="When a Questionnaire is marked as complete in the Questionnaire Assistant, Quilt will automatically import the responses from that questionnaire to the Answer Bank."
          name="import_questionnaire_on_complete"
          valuePropName="checked"
          className=""
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {submitting ? "Saving..." : "Save"}
            </Button>
            <Button htmlType="button" onClick={() => form.resetFields()}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Skeleton>
  )
}

export default SettingsTab
