import Popover from "antd/es/popover"
import { AlertTriangle } from "lucide-react"

interface Props {
  confidence: number
}

const Title: React.FC<Partial<Props>> = ({ confidence }) => (
  <div className="flex flex-row items-center justify-start gap-2 border-orange-100 text-orange-500">
    <AlertTriangle size={16} />
    {confidence === undefined ? (
      <div className="text-sm font-bold">Low Confidence</div>
    ) : (
      <div className="text-sm font-bold">
        Confidence: {confidence.toFixed(2)}
      </div>
    )}
  </div>
)

const ConfidenceScore: React.FC<Props> = ({ confidence }) => {
  if (confidence > 0.5 || confidence === 0) {
    return null
  }

  return (
    <Popover
      title={
        <div className="w-72 p-2">
          <Title confidence={confidence} />
          <div className="mt-2 text-sm font-normal text-gray-400">
            This response may be incomplete, incorrect, or contain unwanted
            language. Please review check the references for correctness before
            using it.
          </div>
        </div>
      }
    >
      <div>
        <Title />
      </div>
    </Popover>
  )
}

export default ConfidenceScore
