import Button from "antd/es/button"
import Input from "antd/es/input"
import Modal from "antd/es/modal"
import { CheckIcon, ExpandIcon, PencilIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { VOID_FUNCTION } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import useSearchParamValue from "../../hooks/useSearchParamValue"
import type { SheetText } from "../../types/answerer"
import type { GeneratedAnsweredQuestion } from "../../types/jobs"
import type { SheetsRange } from "../../types/sheets"
import ConfidenceScore from "../ConfidenceScore"
import Discussions from "../Discussions/Discussions"
import CellHistory from "./CellHistory"
import { EmptyResponse } from "./EmptyResponse"
import ReferenceSelector from "./ReferencesPicker"
import type { AnswerWithDiscussion } from "./types"
import { getCellLabel } from "./utils"

const { TextArea } = Input

const LocationLabel: React.FC<{
  location: SheetsRange | null | undefined
  jobOid: string
  questionId: string | null | undefined
}> = ({ location, jobOid, questionId }) => {
  if (!location) return null

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`/questionnaire-assistant/review/${jobOid}?sheetName=${location?.sheetName}&firstRowIndex=${location?.firstRowIndex}&firstColIndex=${location?.firstColIndex}`}
      className="hover:bg-primary ml-2 cursor-pointer rounded-md bg-gray-50 px-1 text-sm font-bold text-gray-500 transition-all duration-200 hover:text-white"
    >
      {questionId ?? getCellLabel(location)}
    </a>
  )
}

interface QuestionAnswerBlockProps {
  jobOid: string
  answerObj: AnswerWithDiscussion
  onUpdateAnswer: (
    primaryAnswer: string,
    secondaryAnswer: string,
  ) => Promise<void>
}

const QuestionAnswerBlock: React.FC<QuestionAnswerBlockProps> = ({
  jobOid,
  answerObj,
  onUpdateAnswer,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [focusedOid, setFocusedOid] = useSearchParamValue("focusedOid")
  const [isUpdating, setIsUpdating] = useState(false)
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [editedPrimaryAnswer, setEditedPrimaryAnswer] = useState(
    answerObj.primary_answer?.text ?? "",
  )
  const [editedSecondaryAnswer, setEditedSecondaryAnswer] = useState(
    answerObj.secondary_answer?.text ?? "",
  )

  const location = answerObj.primary_question.location
  const handleUpdateClick = async () => {
    setIsUpdating(true)
    try {
      await onUpdateAnswer(editedPrimaryAnswer, editedSecondaryAnswer)
    } finally {
      setIsUpdating(false)
      setIsEditing(false)
    }
  }

  const enterFocusView = useCallback(() => {
    setFocusedOid(answerObj.oid)
  }, [setFocusedOid, answerObj.oid])

  const exitFocusView = useCallback(() => {
    setFocusedOid(null)
  }, [setFocusedOid])

  const onAnswerChanged = useCallback(
    (newAnswer: GeneratedAnsweredQuestion) => {
      setEditedPrimaryAnswer(newAnswer.primary_answer.text)
      setEditedSecondaryAnswer(newAnswer.secondary_answer?.text ?? "")
      // TODO(mgraczyk): Handle updating references.
    },
    [],
  )

  const renderQuestion = (
    question: SheetText,
    isSecondary: boolean,
    questionId: string | null | undefined,
  ) => (
    <div className="mb-4">
      <div className="flex items-center">
        <span className="text-[14px] font-semibold text-gray-700">
          {isSecondary ? "Question Details" : "Question"}
        </span>
        <LocationLabel
          location={question.location}
          jobOid={jobOid}
          questionId={questionId}
        />
      </div>
      <p className="mt-1 whitespace-pre-wrap text-[14px] text-gray-700">
        {question.text}
      </p>
    </div>
  )

  const renderAnswer = (
    answer: SheetText,
    editing: boolean,
    isSecondary = false,
  ) => (
    <div className="mt-2">
      <div className="flex items-center">
        <span className="text-[14px] font-semibold text-gray-700">
          {isSecondary ? "Answer Explanation" : "Answer"}
        </span>
        <LocationLabel
          location={answer.location}
          jobOid={jobOid}
          questionId={null}
        />
      </div>
      {editing ? (
        <TextArea
          value={isSecondary ? editedSecondaryAnswer : editedPrimaryAnswer}
          onChange={(e) =>
            isSecondary
              ? setEditedSecondaryAnswer(e.target.value)
              : setEditedPrimaryAnswer(e.target.value)
          }
          autoSize={{ minRows: 2 }}
          className="mt-1 w-full"
        />
      ) : answer?.text || isSecondary ? (
        <p className="mt-1 whitespace-pre-wrap text-[14px] text-gray-700">
          {answer.text ?? ""}
        </p>
      ) : (
        <EmptyResponse />
      )}
    </div>
  )

  const renderQuestionAnswer = (
    answer: AnswerWithDiscussion,
    editing: boolean,
  ) => (
    <div className="mb-4">
      {renderQuestion(answer.primary_question, false, answer.question_id)}
      {answer.secondary_question &&
        renderQuestion(answer.secondary_question, true, null)}
      {renderAnswer(answer.primary_answer, editing)}
      {answer.secondary_answer &&
        renderAnswer(answer.secondary_answer, editing, true)}
      {!answer.last_reviewed_by && (
        <div className="flex">
          <ConfidenceScore confidence={answer.confidence} />
        </div>
      )}
    </div>
  )

  const modalContent = (
    <div className="flex h-full">
      <div className="w-1/3 border-r p-4">
        {renderQuestionAnswer(answerObj, true)}
        <Button
          icon={<CheckIcon size={16} />}
          onClick={handleUpdateClick}
          type="primary"
          disabled={isUpdating}
          loading={isUpdating}
        >
          Update
        </Button>
      </div>
      <ReferenceSelector
        jobOid={jobOid}
        answerOid={answerObj.oid}
        initialReferences={answerObj.references}
        onAnswerChanged={onAnswerChanged}
        className="w-1/3 border-r px-4"
      />
      <div className="w-1/3 px-4">
        <h3 className="mb-2 text-lg font-semibold">History</h3>
        <div className="max-h-96 overflow-y-auto">
          <CellHistory
            answer_oid={answerObj.oid}
            group_oid={activeGroupOid}
            job_oid={jobOid}
          />
        </div>
        {location && (
          <div className="my-4 max-h-96 overflow-y-auto">
            <h3 className="mb-2 text-lg font-semibold">Discussions</h3>
            <Discussions
              group_oid={activeGroupOid}
              discussions={answerObj.discussions}
              job_id={jobOid}
              location={location}
              kind="SHEET"
              onClickLocation={VOID_FUNCTION}
              hideTitle
            />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <>
      {renderQuestionAnswer(answerObj, isEditing)}
      <div className="mt-4">
        {isEditing ? (
          <div className="flex">
            <Button
              icon={<CheckIcon />}
              onClick={handleUpdateClick}
              type="primary"
              size="small"
              disabled={isUpdating}
              loading={isUpdating}
            >
              Update
            </Button>
            <Button
              onClick={() => setIsEditing(false)}
              type="text"
              size="small"
              className="ml-2"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <>
            <Button
              icon={<PencilIcon />}
              onClick={() => setIsEditing(true)}
              size="small"
              className="text-xs"
            >
              Edit
            </Button>
            <Button
              icon={<ExpandIcon />}
              onClick={enterFocusView}
              size="small"
              className="ml-2 text-xs"
            >
              Focus
            </Button>
          </>
        )}
      </div>
      {/* TODO(mgraczyk): See if there's a way to consolidate styles and height */}
      <Modal
        open={focusedOid === answerObj.oid}
        onCancel={exitFocusView}
        footer={null}
        destroyOnClose
        width="100%"
        className="!p-0"
        wrapClassName="m-6 [&_.ant-modal-content]:!p-0 [&_.ant-modal-body]:!h-[calc(100vh-80px)]"
        style={{ top: 0 }}
        styles={{
          body: {
            padding: 0,
            overflow: "hidden",
          },
          content: {
            padding: 0,
            overflow: "hidden",
          },
        }}
      >
        {modalContent}
      </Modal>
    </>
  )
}

export default QuestionAnswerBlock
