import Button from "antd/es/button"
import Empty from "antd/es/empty"
import { collection } from "firebase/firestore"
import { LoaderCircleIcon, PlusIcon } from "lucide-react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"

import Header from "../components/Header"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { LIVE_NOTES_TEMPLATE_SUBCOLLECTION } from "../live/db"
import type { LiveNotesTemplate } from "../live/types"
import { GROUPS_COLLECTION } from "../types/common"

const LiveNotesTemplatePreview: React.FC<{ template: LiveNotesTemplate }> = ({
  template,
}) => {
  return (
    <div className="rounded-xl border p-2">
      <Link to={template.oid}>
        <h4>{template.name}</h4>
      </Link>
    </div>
  )
}

const LiveNotesTemplateList: React.FC = () => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [templates, templatesLoading] = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      LIVE_NOTES_TEMPLATE_SUBCOLLECTION,
    ).withConverter(makeConverter<LiveNotesTemplate>()),
  )

  return (
    <div className="p-4">
      {templatesLoading ? (
        <LoaderCircleIcon
          className="text-primary mt-4 w-full animate-spin"
          size={64}
        />
      ) : templates?.length === 0 ? (
        <Empty description="No templates yet, click Add Template to create one" />
      ) : (
        templates?.map((template) => (
          <LiveNotesTemplatePreview key={template.oid} template={template} />
        ))
      )}
    </div>
  )
}

const LiveNotesTemplatesPage: React.FC = () => {
  return (
    <>
      <Header
        title="Live Notes Templates"
        breadcrumbs={[
          {
            title: "Live Assistant",
            href: "/live-assistant",
          },
          {
            title: "Live Notes Templates",
            href: "/live-assistant/live-notes-templates",
          },
        ]}
      >
        <Link to="/live-assistant/live-notes-templates/new">
          <Button icon={<PlusIcon />} type="primary">
            Add Template
          </Button>
        </Link>
      </Header>
      <LiveNotesTemplateList />
    </>
  )
}

export default LiveNotesTemplatesPage
