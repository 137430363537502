import type { CRMAction, CRMFieldUpdate } from "../../crm/types.js"

// /groups/{groupOid}/playbooks/{playbookOid}
export const PLAYBOOKS_SUBCOLLECTION = "playbooks"

interface UserActor {
  uid: string
  email: string
}

// PlaybookActionTemplate is the definitions of actions that can be created.
type CRMUpdateActionTemplate = Pick<
  CRMFieldUpdate,
  "kind" | "object_type" | "field_name"
>

type PlaybookActionTemplate = CRMUpdateActionTemplate

export interface PlaybookCriteria {
  oid: string
  name: string

  // Model that will be used to evaluate the criteria.
  model_oid?: string

  action_template?: PlaybookActionTemplate
}

export interface PlaybookSection {
  name: string
  criteria: PlaybookCriteria[]
}

export interface Playbook {
  oid: string

  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp

  name: string
  description?: string
  sections: PlaybookSection[]

  notes_template_oid?: string

  created_by: UserActor
  last_updated_by: UserActor
}

// Context about the call or interaction necessary to generate actions.
// For example, the Account and Opportunity would be included here.
export interface PlaybookContext {
  // TODO(mgraczyk): Somehow get things like Account and Opportunity.
  // We may want to support multiple opportunities being discussed on a single
  // call, but then we need to be able to somehow disambiguate given the
  // transcript.
  ignore: never
}

// An PlaybookAction is created from an ActionTemplate, a playbook model, and
// PlaybookContext.
type PlaybookAction = CRMAction

// Output of the criteria
export interface PlaybookCriteriaResult {
  model_oid: string

  was_attempted: boolean
  was_completed: boolean

  model_explanation?: string
  model_output?: string | number
  user_override?: string | number

  // Action that will be or was taken, if any.
  action?: PlaybookAction

  // TODO(mgraczyk): Pointer into transcript.
}

// The output result of processing the playbook on the call.
export interface PlaybookOutput {
  oid: string

  playbook: Playbook
  done_processing: boolean

  criteria_results: {
    [criteria_oid: string]: PlaybookCriteriaResult
  }

  notes?: string
}

type PlaybookCriteriaCategory =
  | "PROSPECT_DETAILS"
  | "QUALIFICATION"
  | "PURCHASING_PROCESS"
  | "MOVING_FORWARD"

export interface PlaybookCriteriaModel {
  oid: string
  name: string
  description: string
  category: PlaybookCriteriaCategory
}

export interface CallOutput {
  oid: string
  source_kind: "LIVE_ASSISTED_CALL" | "GONG"
  external_id: string
  transcript_uri: string | null
  playbook_output: PlaybookOutput | null
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
  created_by: UserActor
  last_updated_by: UserActor
  ui_visible: boolean
}
