import { sendAnalyticsEvent } from "../../analytics"
import { createUserDocuments } from "../../api"
import uploadUserFile from "../../files/uploadUserFile"
import type { UserDocumentCreationRequest } from "../../types/userDocument"
import {
  type FileDocument,
  type GoogleDriveDocument,
  isFileDocument,
  isGoogleDriveDocument,
} from "./state"
import { getQuestionAnswerLayouts } from "./utils"

const indexDocument = async (doc: FileDocument | GoogleDriveDocument) => {
  const questionAnswerLayouts = getQuestionAnswerLayouts(doc)
  let documentsToCreate: UserDocumentCreationRequest[]
  if (isFileDocument(doc)) {
    if (
      doc.fileContents === undefined ||
      doc.fileType === undefined ||
      doc.fileName === undefined
    ) {
      throw new Error("No file")
    }

    const { id: uploadId, uri: objectUri } = await uploadUserFile(
      doc.fileContents,
      "source_document_uploads",
      doc.fileType,
      doc.fileName,
    )
    documentsToCreate = [
      {
        external_document_id: uploadId,
        url: "",
        source_kind: "FILEUPLOAD",
        uploaded_file_uri: objectUri,
        title: doc.fileName,
        mimetype: doc.mimeType,
        question_answer_layouts: questionAnswerLayouts,
      },
    ]
  } else if (isGoogleDriveDocument(doc)) {
    documentsToCreate = [
      {
        external_document_id: doc.external_document_id,
        url: "",
        source_kind: "GOOGLE_DRIVE",
        mimetype: doc.mimeType,
        title: doc.title,
        question_answer_layouts: questionAnswerLayouts,
      },
    ]
  } else {
    throw new Error("Unknown doc type")
  }

  const documents = await createUserDocuments(documentsToCreate)
  documents.map((doc) => {
    void sendAnalyticsEvent({
      surface: "WEB_SOURCE_DOCUMENTS",
      event_type: "CREATE_ENTITY",
      event_data: {
        entity_type:
          doc.source_kind === "GOOGLE_DRIVE"
            ? "SOURCE_DOCUMENT_GOOGLE_DRIVE"
            : "SOURCE_DOCUMENT_FILE",
        entity_id: doc.oid,
      },
    })
  })
}

export default indexDocument
