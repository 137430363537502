import Button from "antd/es/button"
import Tooltip from "antd/es/tooltip"
import { ThumbsUpIcon } from "lucide-react"

import type { AnalyticsEventSurface } from "../analytics/types"
import useAnswerFeedback from "../hooks/useAnswerFeedback"
import type { Answer } from "../types/answerer"
import BadAnswerButton from "./BadAnswerButton"

interface Props {
  surface: AnalyticsEventSurface
  answer: Pick<Answer, "confidence">
  answererRequestId: string | null | undefined
  disabled?: boolean
}

const AnswerFeedbackButtons: React.FC<Props> = ({
  surface,
  answer,
  answererRequestId,
  disabled,
}) => {
  const { onGoodAnswer } = useAnswerFeedback(surface, answererRequestId)
  return (
    <>
      <Tooltip placement="topRight" title="Good Answer">
        <Button
          icon={<ThumbsUpIcon />}
          size="small"
          disabled={disabled}
          onClick={onGoodAnswer}
        />
      </Tooltip>
      <BadAnswerButton
        surface={surface}
        answererRequestId={answererRequestId}
        unableToAnswer={answer.confidence === 0}
        disabled={disabled}
      />
    </>
  )
}

export default AnswerFeedbackButtons
