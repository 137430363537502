import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import Input from "antd/es/input"
import Skeleton from "antd/es/skeleton"
import Tooltip from "antd/es/tooltip"
import {
  CircleCheckIcon,
  CircleDashedIcon,
  PencilIcon,
  RedoIcon,
  XIcon,
} from "lucide-react"
import { useCallback, useMemo, useState } from "react"

import { getReferenceSearchResults } from "../../api"
import useErrorPopup from "../../hooks/useErrorPopup"
import { reanswerQuestionnaireJobAnswers } from "../../pages/QuestionnaireAssistant/api"
import type { AnswerReference } from "../../types/answerer"
import type { GeneratedAnsweredQuestion } from "../../types/jobs"
import ChatReference from "../Chatv2/ChatReference"

const { Search } = Input

export const getCompoundReferenceKey = (ref: AnswerReference) =>
  `${ref.document_oid}:${ref.piece_oid}:${ref?.knowledge_item_oid}`

interface ReferenceSelectorProps {
  initialReferences: AnswerReference[]
  jobOid: string
  answerOid: string
  onAnswerChanged: (answer: GeneratedAnsweredQuestion) => void
  className?: string
}

const ReferenceSelector: React.FC<ReferenceSelectorProps> = ({
  initialReferences,
  jobOid,
  answerOid,
  onAnswerChanged,
  className,
}) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [search, setSearch] = useState("")
  const [searching, setSearching] = useState(false)
  const [reanswering, setReanswering] = useState(false)
  const [searchResults, setSearchResults] = useState<AnswerReference[]>([])
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [editedReferences, setEditedReferences] =
    useState<AnswerReference[]>(initialReferences)

  const { handleError, handleSuccess } = useErrorPopup()

  // Create a Set of initial reference keys for efficient lookup
  const initialRefKeys = useMemo(
    () => new Set(initialReferences.map(getCompoundReferenceKey)),
    [initialReferences],
  )

  const currentRefKeys = useMemo(
    () => new Set(editedReferences.map(getCompoundReferenceKey)),
    [editedReferences],
  )

  const removedReferences = useMemo(() => {
    return initialReferences.filter(
      (ref) => !currentRefKeys.has(getCompoundReferenceKey(ref)),
    )
  }, [initialReferences, currentRefKeys])

  const addedReferences = useMemo(() => {
    return editedReferences.filter(
      (ref) => !initialRefKeys.has(getCompoundReferenceKey(ref)),
    )
  }, [editedReferences, initialRefKeys])

  const hasChanges = addedReferences.length > 0 || removedReferences.length > 0

  const handleSearch = useCallback(async () => {
    if (search === "") return
    setSearching(true)
    try {
      const result = await getReferenceSearchResults({
        query: { text: search },
        analyticsSurface: "WEB_REFERENCE_SEARCH",
      })
      setSearchResults(result.references)
      setDropdownVisible(true)
    } catch (error) {
      console.error("Error fetching references:", error)
    } finally {
      setSearching(false)
    }
  }, [search])

  const removeReference = useCallback((reference: AnswerReference) => {
    setEditedReferences((prev) =>
      prev.filter(
        (ref) =>
          getCompoundReferenceKey(ref) !== getCompoundReferenceKey(reference),
      ),
    )
  }, [])

  const toggleReference = useCallback(
    (reference: AnswerReference) => {
      const refKey = getCompoundReferenceKey(reference)

      setEditedReferences((prev) => {
        if (currentRefKeys.has(refKey)) {
          // Remove if it exists (but keep at least one reference)
          const filtered = prev.filter(
            (ref) => getCompoundReferenceKey(ref) !== refKey,
          )
          return filtered.length > 0 ? filtered : prev
        } else {
          // Add to the beginning if it doesn't exist
          return [reference, ...prev]
        }
      })
    },
    [currentRefKeys],
  )

  const handleCancel = useCallback(() => {
    setIsEditMode(false)
    setEditedReferences(initialReferences)
    setSearch("")
    setSearchResults([])
    setDropdownVisible(false)
  }, [initialReferences])

  const handleRegenerate = useCallback(async () => {
    setReanswering(true)
    try {
      const newAnswer = (
        await reanswerQuestionnaireJobAnswers(
          jobOid,
          [answerOid],
          addedReferences.map((ref) => ({
            user_document_oid: ref.document_oid,
            piece_oid: ref.piece_oid,
          })),
          removedReferences.map((ref) => ({
            user_document_oid: ref.document_oid,
            piece_oid: ref.piece_oid,
          })),
        )
      ).answers[0]
      onAnswerChanged(newAnswer)
      handleSuccess("Reanswered question")
    } catch (error) {
      handleError({
        error,
        prefix: "Couldn't reanswer",
      })
    } finally {
      setReanswering(false)
    }
  }, [
    answerOid,
    addedReferences,
    removedReferences,
    handleSuccess,
    handleError,
    jobOid,
    onAnswerChanged,
  ])

  const renderSearchResults = () => (
    <div className="w-[600px] rounded-lg border border-gray-200 bg-white shadow-lg">
      {searching ? (
        <div className="p-4">
          <Skeleton active paragraph={{ rows: 2 }} />
        </div>
      ) : searchResults.length > 0 ? (
        <div className="max-h-[400px] overflow-y-auto py-2">
          {searchResults.map((reference) => {
            const isAdded = currentRefKeys.has(
              getCompoundReferenceKey(reference),
            )
            return (
              <div
                key={getCompoundReferenceKey(reference)}
                className={`group flex cursor-pointer items-start justify-between px-4 py-2 ${isAdded ? "bg-purple-25 hover:bg-purple-50" : "bg-white hover:bg-gray-50"}`}
                onClick={() => toggleReference(reference)}
              >
                <ChatReference
                  reference={reference}
                  idx={0}
                  loading={false}
                  loadKnowledgeItemEagerly={true}
                  className="flex-1 text-sm"
                  hideIndex
                />

                <CircleCheckIcon
                  className={`ml-2 text-purple-600 ${isAdded ? "visible" : "invisible"}`}
                />
              </div>
            )
          })}
        </div>
      ) : (
        <div className="p-4 text-center text-gray-500">No results found</div>
      )}
    </div>
  )

  return (
    <div className={`flex flex-col pb-4 ${className}`}>
      <div className="flex items-center">
        <h3 className="grow text-lg font-semibold">References</h3>
        {hasChanges && (
          <Tooltip title="Click Regenerate to save change">
            <CircleDashedIcon />
          </Tooltip>
        )}
        {isEditMode ? (
          <Button type="text" icon={<XIcon />} onClick={handleCancel}>
            Cancel
          </Button>
        ) : (
          <Button
            type="text"
            icon={<PencilIcon size="1rem" />}
            onClick={() => setIsEditMode(true)}
          >
            Edit
          </Button>
        )}
      </div>

      {isEditMode && (
        <Dropdown
          open={dropdownVisible && searchResults.length > 0}
          onOpenChange={setDropdownVisible}
          trigger={["click"]}
          dropdownRender={renderSearchResults}
        >
          <Search
            placeholder="Search for references..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={handleSearch}
            className="mb-4 w-full"
            loading={searching}
            disabled={searching || reanswering}
          />
        </Dropdown>
      )}

      <div className="grow overflow-y-auto">
        {editedReferences.map((ref, index) => {
          const isNewlyAdded =
            isEditMode && !initialRefKeys.has(getCompoundReferenceKey(ref))
          return (
            <div
              key={getCompoundReferenceKey(ref)}
              className={`mb-4 rounded border transition-all duration-300 ${
                isNewlyAdded
                  ? "border-blue-400 bg-blue-50 shadow-md"
                  : "border-gray-200"
              }`}
            >
              <div className="flex items-start justify-between p-2">
                <ChatReference
                  reference={ref}
                  idx={index}
                  loading={false}
                  loadKnowledgeItemEagerly={true}
                  className="flex-1"
                />
                {isEditMode && (
                  <Button
                    type="text"
                    icon={<XIcon />}
                    size="small"
                    className="ml-2 flex h-6 w-6 items-center justify-center"
                    onClick={() => removeReference(ref)}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div className="flex items-center justify-end space-x-2 pt-4">
        {isEditMode && (
          <Button onClick={handleCancel} disabled={reanswering}>
            Cancel
          </Button>
        )}
        <Button
          type="primary"
          icon={<RedoIcon />}
          onClick={handleRegenerate}
          disabled={reanswering}
          loading={reanswering}
        >
          Regenerate
        </Button>
      </div>
    </div>
  )
}

export default ReferenceSelector
