import { httpsCallable } from "../serverUtils"
import {
  type CreateGroupTagArgs,
  type EditGroupTagArgs,
  type RemoveGroupTagArgs,
} from "../tags/types"
import type {
  CreateGroupSSOConfigRequest,
  DeleteGroupSSOConfigRequest,
  GetSSOConfigRequest,
  GetSSOConfigResponse,
} from "./signin/types.js"
import {
  type CreateGroupArgs,
  type CreateGroupInviteArgs,
  type QuiltAdminAddGroupMemberArgs,
  type QuiltAdminUpdateGroupArgs,
  type RemoveGroupMemberArgs,
  type SwitchGroupArgs,
  type UpdateGroupArgs,
  type UpdateGroupMemberArgs,
} from "./types"

export const createGroupInvite =
  httpsCallable<CreateGroupInviteArgs>("createGroupInvite")

export const acceptGroupInvite = httpsCallable<{
  oid: string
}>("acceptGroupInvite")

export const removeGroupInvite = httpsCallable<{
  oid: string
}>("removeGroupInvite")

export const updateUserGroupRole = httpsCallable<UpdateGroupMemberArgs>(
  "updateUserGroupRole",
)

export const removeGroupMember =
  httpsCallable<RemoveGroupMemberArgs>("removeGroupMember")

export const updateGroup = httpsCallable<UpdateGroupArgs, void>("updateGroup")

export const deleteGroupSSO = httpsCallable<DeleteGroupSSOConfigRequest, void>(
  "deleteGroupSSO",
)
export const createGroupSSO = httpsCallable<CreateGroupSSOConfigRequest, void>(
  "createGroupSSO",
)
export const getSSOConfig = httpsCallable<
  GetSSOConfigRequest,
  GetSSOConfigResponse
>("getSSOConfig", { authNotRequired: true })

// Admin API.
// Can only be called by Quilt admins.
export const adminCreateGroup = httpsCallable<CreateGroupArgs, { oid: string }>(
  "adminCreateGroup",
)
export const adminUpdateGroup = httpsCallable<QuiltAdminUpdateGroupArgs, void>(
  "adminUpdateGroup",
)
export const adminAddGroupMember = httpsCallable<
  QuiltAdminAddGroupMemberArgs,
  void
>("adminAddGroupMember")

export const adminCreateGroupTag = httpsCallable<CreateGroupTagArgs, void>(
  "adminCreateGroupTag",
)

export const adminRemoveGroupTag = httpsCallable<RemoveGroupTagArgs, void>(
  "adminRemoveGroupTag",
)

export const adminUpdateGroupTag = httpsCallable<EditGroupTagArgs, void>(
  "adminUpdateGroupTag",
)

export const switchGroupApi = httpsCallable<SwitchGroupArgs>("switchGroup")
