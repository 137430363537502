import type { User as AuthUser } from "firebase/auth"
import { LoaderCircleIcon } from "lucide-react"
import { Suspense, lazy } from "react"
import { Navigate, Route, Routes } from "react-router"

import type { QuiltFlags } from "../groups/types"
import KnowledgeItemPage from "../knowledge/KnowledgeItemPage"
import AssignmentsPage from "../pages/Assignments"
import CallOutputsPage from "../pages/CallOutputs"
import CallOutputPage from "../pages/CallOutputs/CallOutput"
import ChatPage from "../pages/ChatPagev2"
import HomePage from "../pages/HomePage"
import KnowledgeItemsTable from "../pages/KnowledgeItemsTable"
import LiveNotesTemplateEditorPage from "../pages/LiveNotesTemplateEditorPage"
import LiveNotesTemplatesPage from "../pages/LiveNotesTemplatesPage"
import LiveStaticCardEditorPage from "../pages/LiveStaticCardEditorPage"
import LiveStaticCardsPage from "../pages/LiveStaticCardsPage"
import NotificationPreferences from "../pages/NotificationPreferences"
import NotificationsPage from "../pages/NotificationsPage"
import QuestionnaireAssistantPage from "../pages/QuestionnaireAssistant"
import ReferenceSearchPage from "../pages/ReferenceSearchPage"
import UserDocumentPage from "../pages/UserDocumentPage"
import UserDocumentsPage from "../pages/UserDocumentsPage"
import UserProfilePage from "../pages/UserProfilePage"
import StartWebLiveAssistantPage from "../pages/WebLiveAssistant"
import WebLiveAssistedCallPage from "../pages/WebLiveAssistant/Call"
import EditPlaybookPage from "../pages/WebLiveAssistant/EditPlaybookPage"
import PlaybooksListPage from "../pages/WebLiveAssistant/PlaybooksListPage"
import WebLiveAssistantSimulationPage from "../pages/WebLiveAssistant/Simulation"
import AcceptInvitePage from "./AcceptInvitePage"
import GroupSettingsPage from "./Groups/GroupSettingsPage"
import UserGroupsManager from "./Groups/UserGroupsManager"
import NotFoundPage from "./NotFoundPage"
import QuestionnaireReview from "./QuestionnaireReview"
import QuestionnaireAnswers from "./QuestionnaireReview/QuestionnaireAnswers"
import "./TopLevelPageContainer.css"

const getLazy = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  importFunc: () => Promise<{ default: React.ComponentType<any> }>,
) => {
  const LazyComponent = lazy(importFunc)
  return function WrappedLazyComponent() {
    return (
      <Suspense
        fallback={<LoaderCircleIcon className="text-primary animate-spin" />}
      >
        <LazyComponent />
      </Suspense>
    )
  }
}

const SlackInstallError = getLazy(() => import("./SlackInstallError"))
const SlackInstallSuccess = getLazy(() => import("./SlackInstallSuccess"))
const SlackLinkAccount = getLazy(() => import("./SlackLinkAccount"))
const QAPageResults = getLazy(() => import("../pages/QAPage/QAPageResults"))
const QAPageStart = getLazy(() => import("../pages/QAPage/QAPageStart"))
const PlansPage = getLazy(() => import("../billing/PlansPage"))
const AdminPage = getLazy(() => import("../quiltAdmin/AdminPage"))
const CrawlsPage = getLazy(() => import("../crawls/WebsiteCrawlsTable"))
const LiveCoachingBotPage = getLazy(
  () => import("../pages/LiveCoachingPage/BotPage"),
)
const LiveCoachingBotsPage = getLazy(() => import("../pages/LiveCoachingPage"))
const LiveCoachingEditorPage = getLazy(
  () => import("../pages/LiveCoachingBotEditorPage"),
)

const AppRoutes: React.FC<{ user: AuthUser; flags: QuiltFlags }> = ({
  user,
}) => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/source-documents"
        element={<UserDocumentsPage user={user} />}
      />
      <Route path="/source-documents/:docOid" element={<UserDocumentPage />} />
      <Route path="/website-crawls" element={<CrawlsPage />} />
      <Route
        path="/questionnaire-assistant"
        element={<QuestionnaireAssistantPage user={user} />}
      />
      <Route
        path="/questionnaire-assistant/review/:oid"
        element={<QuestionnaireReview user={user} />}
      />
      <Route
        path="/questionnaire-assistant/review/:questionnaireOid/responses"
        element={<QuestionnaireAnswers />}
      />
      <Route
        path="/questionnaire-assistant/review/feed/:oid"
        element={<QuestionnaireReview user={user} feedView />}
      />
      <Route
        path="/knowledge-items"
        element={<KnowledgeItemsTable user={user} />}
      />
      <Route
        path="/knowledge-items/:docOid"
        element={<KnowledgeItemPage user={user} />}
      />
      <Route
        path="/live-assistant"
        element={<StartWebLiveAssistantPage user={user} />}
      />
      <Route
        path="/live-assistant/:callOid"
        element={<WebLiveAssistedCallPage user={user} />}
      />
      <Route path="/live-assistant/cards" element={<LiveStaticCardsPage />} />
      <Route path="/live-assistant/playbooks" element={<PlaybooksListPage />} />
      <Route
        path="/live-assistant/playbooks/:playbookOid"
        element={<EditPlaybookPage />}
      />

      <Route
        path="/live-assistant/cards/:staticCardContentOid"
        element={<LiveStaticCardEditorPage />}
      />
      <Route
        path="/live-assistant/live-notes-templates/:liveNotesTemplateOid"
        element={<LiveNotesTemplateEditorPage />}
      />
      <Route
        path="/live-assistant/live-notes-templates"
        element={<LiveNotesTemplatesPage />}
      />
      <Route
        path="/live-assistant-simulation/:callOid"
        element={<WebLiveAssistantSimulationPage user={user} />}
      />
      <Route path="/slack-link-account" element={<SlackLinkAccount />} />
      <Route
        path="/slack/success/:teamId/:appId"
        element={<SlackInstallSuccess />}
      />
      <Route path="/slack/error" element={<SlackInstallError />} />
      <Route path="/assignments" element={<AssignmentsPage user={user} />} />
      <Route path="/reference-search" element={<ReferenceSearchPage />} />
      <Route path="/chat" element={<ChatPage user={user} />} />
      <Route path="/chat/:sessionOid" element={<ChatPage user={user} />} />
      <Route
        path="/group/settings"
        element={<GroupSettingsPage user={user} />}
      />
      <Route
        path="/group/settings/:settingsPage"
        element={<GroupSettingsPage user={user} />}
      />
      <Route path="/group/switch" element={<UserGroupsManager />} />
      <Route path="/profile" element={<UserProfilePage user={user} />} />
      <Route
        path="/profile/notification-preferences"
        element={<NotificationPreferences />}
      />
      <Route
        path="/notifications"
        element={<NotificationsPage user={user} />}
      />
      <Route path="/group/settings/billing/plans" element={<PlansPage />} />

      <Route
        path="/questionnaires-workflow"
        element={<Navigate to="/questionnaire-assistant" replace />}
      />
      <Route
        path="/questionnaires-workflow/review/:oid"
        element={<Navigate to="/questionnaire-assistant" replace />}
      />
      <Route
        path="/groups"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/group"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/group/reporting"
        element={<Navigate to="/group/settings/reporting" replace />}
      />
      <Route
        path="/billing"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/billing/plans"
        element={<Navigate to="/group/settings/billing/plans" replace />}
      />
      <Route path="/answer-search" element={<Navigate to="/chat" replace />} />
      <Route
        path="/live-coaching"
        element={<Navigate to="/coaching-assistant" replace />}
      />
      <Route
        path="/live-coaching/:botOid"
        element={<Navigate to="/coaching-assistant" replace />}
      />
      <Route path="/coaching-assistant" element={<LiveCoachingBotsPage />} />
      <Route
        path="/coaching-assistant/:botOid"
        element={<LiveCoachingBotPage />}
      />
      <Route
        path="/coaching-assistant/:botOid/edit"
        element={<LiveCoachingEditorPage />}
      />
      <Route
        path="/live-assistant-web"
        element={<Navigate to="/live-assistant" replace />}
      />
      <Route
        path="/live-assistant-web/:callOid"
        element={<Navigate to="/live-assistant" replace />}
      />
      <Route path="/calls" element={<CallOutputsPage />} />
      <Route path="/calls/:callOid" element={<CallOutputPage />} />

      <Route path="/qa-start" element={<QAPageStart />} />
      <Route path="/qa-results" element={<QAPageResults />} />
      {/* /slack is linked to from Slack "App Homepage" button */}
      <Route path="/slack" element={<Navigate to="/" replace />} />
      <Route path="/signup" element={<Navigate to="/" replace />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/invite/:id" element={<AcceptInvitePage />} />
      <Route path="/admin/*" element={<AdminPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
