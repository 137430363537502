import Alert from "antd/es/alert"
import Empty from "antd/es/empty"
import Input from "antd/es/input"
import List from "antd/es/list"
import Skeleton from "antd/es/skeleton"
import { useCallback, useState } from "react"

import { getTranscriptSearchResults } from "../../live/api"
import type { TranscriptSearchResponse } from "../../live/types"

interface Props {
  callOid: string
  className?: string
}

const TranscriptSearch: React.FC<Props> = ({ callOid, className = "" }) => {
  const [searching, setSearching] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const [response, setResponse] = useState<TranscriptSearchResponse>()

  const onSearch = useCallback(
    async (query: string) => {
      setSearching(true)
      setError(undefined)
      setResponse(undefined)
      try {
        const response = await getTranscriptSearchResults(callOid, query)
        setResponse(response)
      } catch (error) {
        setError(
          error instanceof Error
            ? error
            : new Error("Search failed, try again later"),
        )
      } finally {
        setSearching(false)
      }
    },
    [callOid],
  )

  const resultSection = error ? (
    <Alert
      message="Error getting results"
      showIcon
      description={error.message}
      type="error"
    />
  ) : (
    <Skeleton
      className="rounded-xl border p-2"
      loading={searching}
      active
      title={false}
    >
      <List
        className="w-full"
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                response ? "No results found" : "Search to get results"
              }
            />
          ),
        }}
        dataSource={response?.results ?? []}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={`Answer: ${item.answer}`}
              description={item.context}
            />
          </List.Item>
        )}
      />
    </Skeleton>
  )

  return (
    <div
      className={
        "flex w-full flex-col items-center gap-2 overflow-y-scroll " + className
      }
    >
      <Input.Search
        className="w-full"
        placeholder="search transcript, or ask a question here..."
        name="search"
        onSearch={onSearch}
        loading={searching}
      />
      {resultSection}
    </div>
  )
}

export default TranscriptSearch
