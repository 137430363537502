import { AxiosError } from "axios"

import { type UserFacingErrorType } from "./exceptions/userFacingErrorTypes.js"
import {
  getPredefinedErrorSuffix,
  getUserFacingErrorType,
} from "./userFacingErrorHelpers.js"

const DEFAULT_SUFFIX =
  "try again later and contact Quilt via slack or support@quilt.app"
const DEFAULT_PREFIX = `Something went wrong`

export interface UserFacingError {
  error: UserFacingErrorType
  detail: string
}

const getUserFacingErrorDetail = (
  errorType: UserFacingErrorType,
  error: unknown,
): string => {
  if (errorType === "UNKNOWN") {
    return ""
  }

  if (error instanceof AxiosError) {
    if (
      error.response?.status &&
      !(error.response.status >= 400 && error.response.status <= 599)
    ) {
      return ""
    }
    const errorData = error.response?.data as {
      detail?: string
      message?: string
    } | null

    if (errorData?.detail != null) {
      return errorData?.detail
    }
    if (errorData?.message != null) {
      return errorData?.message
    }
  } else if (
    error &&
    typeof error === "object" &&
    "detail" in error &&
    typeof error.detail === "string"
  ) {
    return error.detail
  }

  return ""
}

export const getUserFacingError = (error: unknown): UserFacingError => {
  const errorType = getUserFacingErrorType(error)
  return {
    error: errorType,
    detail: getUserFacingErrorDetail(errorType, error),
  }
}

const getErrorSuffix = (error: unknown): string => {
  if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
    return "Could not connect to server"
  }
  const userFacingError = getUserFacingError(error)
  const predefinedErrorSuffix = getPredefinedErrorSuffix(
    userFacingError.error,
    userFacingError.detail,
  )
  return predefinedErrorSuffix || DEFAULT_SUFFIX
}

export const getErrorMessage = (
  errorContext: {
    error?: unknown
    prefix?: string
  },
  log: boolean = true,
): string => {
  const prefix = errorContext.prefix || DEFAULT_PREFIX
  const suffix = getErrorSuffix(errorContext.error)
  const message = `${prefix}, ${suffix}`

  if (log) {
    console.error(message, errorContext.error)
  }
  return message
}
