import type { User as AuthUser } from "firebase/auth"
import { serverTimestamp } from "firebase/firestore"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore"

import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { GROUPS_COLLECTION } from "../../types/common"
import type { Playbook } from "./types"
import { PLAYBOOKS_SUBCOLLECTION } from "./types"

export type PlaybookMutation = Pick<
  Playbook,
  "name" | "description" | "sections" | "notes_template_oid"
>

const colRef = (groupOid: string) =>
  collection(
    db,
    GROUPS_COLLECTION,
    groupOid,
    PLAYBOOKS_SUBCOLLECTION,
  ).withConverter(makeConverter<Playbook>())

// Returns the oid of the newly created card.
export const createPlaybook = async (
  authUser: AuthUser,
  groupOid: string,
  playbook: PlaybookMutation,
): Promise<string> => {
  const newPlaybook = {
    ...playbook,
    oid: "",
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    created_by: {
      uid: authUser.uid,
      email: authUser.email!,
    },
    last_updated_by: {
      uid: authUser.uid,
      email: authUser.email!,
    },
  }
  const result = await addDoc(colRef(groupOid), newPlaybook)

  return result.id
}

export const updatePlaybook = async (
  authUser: AuthUser,
  groupOid: string,
  playbook: PlaybookMutation & { oid: string },
): Promise<void> => {
  const updates = {
    ...playbook,
    updated_at: serverTimestamp(),
    last_updated_by: {
      uid: authUser.uid,
      email: authUser.email!,
    },
  }

  await updateDoc(doc(colRef(groupOid), playbook.oid), updates)
}

export const deletePlaybook = async (
  groupOid: string,
  playbookOid: string,
): Promise<void> => {
  await deleteDoc(doc(colRef(groupOid), playbookOid))
}
