import Button from "antd/es/button"
import Card from "antd/es/card"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Select from "antd/es/select"
import Skeleton from "antd/es/skeleton"
import Typography from "antd/es/typography"
import { collection, doc, limit, query } from "firebase/firestore"
import { XIcon } from "lucide-react"
import { useCallback, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"

import Header from "../../components/Header"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { useActiveGroup } from "../../contexts/useActiveGroup"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { LIVE_NOTES_TEMPLATE_SUBCOLLECTION } from "../../live/db"
import {
  type PlaybookMutation,
  createPlaybook,
  deletePlaybook,
  updatePlaybook,
} from "../../live/playbook/api"
import { CRITERIA_MODELS } from "../../live/playbook/criteria_models"
import {
  PLAYBOOKS_SUBCOLLECTION,
  type Playbook,
  type PlaybookSection,
} from "../../live/playbook/types"
import type { LiveNotesTemplate } from "../../live/types"
import { GROUPS_COLLECTION } from "../../types/common"

const _CRITERIA_MODELS_DROPDOWN_ITEMS = CRITERIA_MODELS.map((model) => {
  return {
    value: model.oid,
    label: model.name,
  }
})

// TODO: Auto-populate based on CRM
const _OBJECT_DROPDOWN_ITEMS = [
  { value: "Opportunity", label: "Opportunity" },
  { value: "Account", label: "Account" },
]

const getFieldDropdownItems = (objectType: string) => {
  if (objectType === "Opportunity") {
    return [
      { value: "Next Steps", label: "Next Steps" },
      { value: "Amount", label: "Amount" },
      { value: "Notes", label: "Notes" },
    ]
  } else if (objectType === "Account") {
    return [{ value: "Name", label: "Name" }]
  }
  return [{ value: "Default", label: "Default" }]
}

const _NEW_PLAYBOOK_OID = "new"

const PlaybookForm: React.FC<{ oid: string }> = ({ oid }) => {
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const [form] = Form.useForm<PlaybookMutation>()
  const sections = Form.useWatch<PlaybookSection[]>("sections", form)

  const { flags, flagsLoading } = useActiveGroup()
  const { handleSuccess, handleError } = useErrorPopup()
  const navigate = useNavigate()

  const [playbook, playbookLoading, playbookError] = useDocumentData(
    oid === _NEW_PLAYBOOK_OID
      ? undefined
      : doc(
          db,
          GROUPS_COLLECTION,
          activeGroupOid,
          PLAYBOOKS_SUBCOLLECTION,
          oid,
        ).withConverter(makeConverter<Playbook>()),
  )
  const [liveNotesTemplates, liveNotesTemplatesLoading] =
    useCollectionData<LiveNotesTemplate>(
      query(
        collection(
          db,
          GROUPS_COLLECTION,
          activeGroupOid,
          LIVE_NOTES_TEMPLATE_SUBCOLLECTION,
        ).withConverter(makeConverter<LiveNotesTemplate>()),
        limit(1000),
      ),
    )

  const savePlaybook = useCallback(
    async (playbook: PlaybookMutation) => {
      setSubmitting(true)

      try {
        if (oid === _NEW_PLAYBOOK_OID) {
          const newOid = await createPlaybook(
            authUser,
            activeGroupOid,
            playbook,
          )
          navigate(`/live-assistant/playbooks/${newOid}`)
        } else {
          await updatePlaybook(authUser, activeGroupOid, {
            ...playbook,
            oid,
          })
        }
        handleSuccess("Playbook saved")
      } catch (error) {
        handleError({ error, prefix: "Couldn't save playbook" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleError, handleSuccess, authUser, activeGroupOid, navigate, oid],
  )

  const deletePlaybookCallback = useCallback(async () => {
    setDeleting(true)
    try {
      await deletePlaybook(activeGroupOid, oid)
      navigate("/live-assistant/playbooks")
    } catch (error) {
      handleError({ error, prefix: "Couldn't delete playbook" })
    } finally {
      setDeleting(false)
    }
  }, [activeGroupOid, handleError, navigate, oid])

  const initialValues: PlaybookMutation = playbook ?? {
    name: "New Playbook",
    description: "",
    sections: [],
  }

  if (playbookError) {
    return <div>Error: {playbookError.message}</div>
  }

  return (
    <div className="flex w-full grow flex-col overflow-y-auto p-8">
      <Skeleton
        loading={playbookLoading || flagsLoading || liveNotesTemplatesLoading}
        active
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name="dynamic_form_complex"
          className="max-w-[1000px]"
          autoComplete="off"
          initialValues={initialValues}
          onFinish={savePlaybook}
        >
          <Form.Item
            name="name"
            label="Playbook Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="MEDICC for PropTech" />
          </Form.Item>
          <Form.Item name="description" label="Playbook Description">
            <Input placeholder="MEDICC Playbook for qualifying PropTech prospects" />
          </Form.Item>

          {flags.showLiveNotetaker && (
            <Form.Item
              name="notes_template_oid"
              label="Notes Template"
              tooltip={
                liveNotesTemplates?.length === 0
                  ? "No templates found. Click View Template in the top right to add one."
                  : "Select the template to use as a scratchpad and checklist for the call."
              }
            >
              <Select
                loading={liveNotesTemplatesLoading}
                placeholder={
                  liveNotesTemplatesLoading
                    ? "Loading..."
                    : liveNotesTemplates?.length === 0
                      ? "No templates found"
                      : "Template name..."
                }
                allowClear
                disabled={
                  submitting ||
                  liveNotesTemplatesLoading ||
                  liveNotesTemplates?.length === 0
                }
                options={
                  liveNotesTemplates?.map((template) => ({
                    label: template.name,
                    value: template.oid,
                  })) ?? []
                }
              />
            </Form.Item>
          )}

          <Form.List name="sections">
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: "flex",
                  rowGap: 16,
                  flexDirection: "column",
                }}
              >
                {fields.map((sectionField) => (
                  <Card
                    size="small"
                    title={sections?.[sectionField.name]?.name}
                    key={sectionField.key}
                    extra={
                      <XIcon
                        onClick={() => {
                          remove(sectionField.name)
                        }}
                      />
                    }
                  >
                    <Form.Item
                      label="Name"
                      name={[sectionField.name, "name"]}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item label="Criteria">
                      <Form.List name={[sectionField.name, "criteria"]}>
                        {(criteriaFields, subOpt) => (
                          <div className="flex flex-col gap-4">
                            {criteriaFields.map((criteriaField) => (
                              <div
                                key={criteriaField.key}
                                className="space-y-4 rounded-md border p-4"
                              >
                                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                  <Form.Item
                                    label="AI Model"
                                    name={[criteriaField.name, "model_oid"]}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select an AI model"
                                      optionFilterProp="label"
                                      options={_CRITERIA_MODELS_DROPDOWN_ITEMS}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label="Goal"
                                    name={[criteriaField.name, "name"]}
                                    rules={[{ required: true }]}
                                  >
                                    <Input placeholder="Type your goal..." />
                                  </Form.Item>
                                </div>
                                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                  <Form.Item
                                    label="CRM Object Type"
                                    name={[
                                      criteriaField.name,
                                      "action_template",
                                      "object_type",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select an object type"
                                      optionFilterProp="label"
                                      options={_OBJECT_DROPDOWN_ITEMS}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label="Field"
                                    name={[
                                      criteriaField.name,
                                      "action_template",
                                      "field_name",
                                    ]}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select a field"
                                      optionFilterProp="label"
                                      options={getFieldDropdownItems(
                                        (sections?.[sectionField.name]
                                          ?.criteria ?? [])[criteriaField.name]
                                          ?.action_template?.object_type ?? "",
                                      )}
                                    />
                                  </Form.Item>
                                </div>
                                <Form.Item
                                  label="Description"
                                  name={[criteriaField.name, "description"]}
                                  initialValue=""
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                  />
                                </Form.Item>
                                <XIcon
                                  onClick={() => {
                                    subOpt.remove(criteriaField.name)
                                  }}
                                />
                              </div>
                            ))}
                            <Button
                              onClick={() =>
                                subOpt.add({
                                  oid: `${sectionField.name}.${criteriaFields.length}`,
                                  name: "",
                                })
                              }
                              block
                            >
                              + Add Criteria
                            </Button>
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                ))}

                <Button onClick={() => add({ name: "", criteria: [] })} block>
                  + Add Section
                </Button>
              </div>
            )}
          </Form.List>

          <Form.Item className="mt-8">
            <Button
              type="primary"
              className="w-26"
              disabled={submitting}
              htmlType="submit"
            >
              {submitting ? "Saving..." : "Save"}
            </Button>
            <Button
              danger
              type="primary"
              className="w-26 ml-2"
              disabled={oid === _NEW_PLAYBOOK_OID || submitting || deleting}
              onClick={deletePlaybookCallback}
            >
              Delete
            </Button>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {() => (
              <Typography>
                <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
              </Typography>
            )}
          </Form.Item>
        </Form>
      </Skeleton>
    </div>
  )
}

const EditPlaybookPage: React.FC = () => {
  const { playbookOid } = useParams<{
    playbookOid: string
  }>()
  const { flags } = useActiveGroup()

  return (
    <>
      <Header
        title="Playbook Editor"
        breadcrumbs={[
          {
            title: "Live Assistant",
            href: "/live-assistant",
          },
          {
            title: "Playbooks",
            href: "/live-assistant/playbooks",
          },
          {
            title: playbookOid ? playbookOid : "New",
            href: `/live-assistant/playbooks/${playbookOid ?? _NEW_PLAYBOOK_OID}`,
          },
        ]}
      >
        {flags.showPlaybooks && (
          <Link to="/live-assistant/live-notes-templates">
            <Button type="default">View/Edit Notes Templates</Button>
          </Link>
        )}
      </Header>
      <PlaybookForm oid={playbookOid ?? _NEW_PLAYBOOK_OID} />
    </>
  )
}

export default EditPlaybookPage
