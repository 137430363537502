import type { User as AuthUser } from "firebase/auth"
import { serverTimestamp } from "firebase/firestore"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore"

import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { GROUPS_COLLECTION } from "../types/common"
import { LIVE_NOTES_TEMPLATE_SUBCOLLECTION } from "./db"
import type { LiveNotesTemplate } from "./types"

type TemplateMutation = Omit<
  LiveNotesTemplate,
  "oid" | "created_at" | "updated_at" | "created_by" | "last_updated_by"
>

const _colRef = (groupOid: string) =>
  collection(
    db,
    GROUPS_COLLECTION,
    groupOid,
    LIVE_NOTES_TEMPLATE_SUBCOLLECTION,
  ).withConverter(makeConverter<LiveNotesTemplate>())

// Returns the oid of the newly created template.
export const createLiveNotesTemplate = async (
  authUser: AuthUser,
  groupOid: string,
  template: TemplateMutation,
): Promise<string> => {
  const newTemplate = {
    ...template,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    created_by: {
      uid: authUser.uid,
      email: authUser.email,
    },
    last_updated_by: {
      uid: authUser.uid,
      email: authUser.email,
    },
  }
  const result = await addDoc(_colRef(groupOid), newTemplate)

  return result.id
}

export const updateLiveNotesTemplate = async (
  authUser: AuthUser,
  groupOid: string,
  template: TemplateMutation & { oid: string },
): Promise<void> => {
  const updates = {
    ...template,
    updated_at: serverTimestamp(),
    last_updated_by: {
      uid: authUser.uid,
      email: authUser.email!,
    },
  }

  await updateDoc(doc(_colRef(groupOid), template.oid), updates)
}

export const deleteLiveNotesTemplate = async (
  groupOid: string,
  templateOid: string,
): Promise<void> => {
  await deleteDoc(doc(_colRef(groupOid), templateOid))
}
