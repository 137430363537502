import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  //InsertImage,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  type MDXEditorMethods,
  Separator,
  UndoRedo,
  diffSourcePlugin,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor"
import "@mdxeditor/editor/style.css"
import { LoaderCircleIcon } from "lucide-react"
import { Suspense, lazy, memo, useEffect, useMemo, useRef } from "react"

const LazyMDXEditor = lazy(() => import("./MDXEditor"))

interface Props {
  onChange: (content: string) => void
  readOnly?: boolean
  initialContent?: string | undefined
  className?: string
}

export const MarkdownToolbar: React.FC = () => {
  return (
    <DiffSourceToggleWrapper>
      <UndoRedo />
      <Separator />
      <BoldItalicUnderlineToggles />
      <CodeToggle />
      <Separator />
      <ListsToggle />
      <Separator />
      <BlockTypeSelect />
      <Separator />

      <CreateLink />
      {/*<InsertImage />*/}

      <Separator />

      <InsertTable />
      <InsertThematicBreak />

      <Separator />
    </DiffSourceToggleWrapper>
  )
}

const MarkdownEditor: React.FC<Props> = ({
  onChange,
  readOnly,
  initialContent,
  className = "",
}) => {
  const editorRef = useRef<MDXEditorMethods>(null)
  const plugins = useMemo(
    () => [
      ...[
        headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
        listsPlugin(),
        quotePlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        // TODO(mgraczyk): imagePlugin
        tablePlugin(),
        thematicBreakPlugin(),
        diffSourcePlugin({
          viewMode: "rich-text",
          diffMarkdown: initialContent,
        }),
        markdownShortcutPlugin(),
      ],
      ...(readOnly
        ? []
        : [toolbarPlugin({ toolbarContents: () => <MarkdownToolbar /> })]),
    ],
    [readOnly, initialContent],
  )

  useEffect(() => {
    // Replace the contents whenever the initial content changes.
    if (editorRef.current && initialContent !== undefined) {
      editorRef.current.setMarkdown(initialContent ?? "")
    }
  }, [initialContent])

  return (
    <Suspense
      fallback={<LoaderCircleIcon className="text-primary animate-spin" />}
    >
      <LazyMDXEditor
        className={className}
        plugins={plugins}
        readOnly={readOnly}
        markdown={initialContent ?? ""}
        onChange={onChange}
        suppressHtmlProcessing
        trim={false}
        ref={editorRef}
      />
    </Suspense>
  )
}

export default memo(MarkdownEditor)
