import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Popconfirm from "antd/es/popconfirm"
import Skeleton from "antd/es/skeleton"
import { Timestamp } from "firebase/firestore"
import { doc } from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useNavigate } from "react-router-dom"

import MarkdownEditor from "../components/MarkdownEditor"
import { VOID_ASYNC_FUNCTION, VOID_FUNCTION } from "../constants"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import { GROUPS_COLLECTION } from "../types/common"
import LiveCard from "./LiveCard"
import { STATIC_CARD_CONTENT_COLLECTION } from "./db"
import {
  createStaticCard,
  deleteStaticCard,
  updateStaticCard,
} from "./staticCardsApi"
import type { StaticCardContent, StaticContentUICard } from "./types"

const { TextArea } = Input

interface Props {
  staticCardContentOid: string
}

const StaticCardEditor: React.FC<Props> = ({ staticCardContentOid }) => {
  const [conditionPrompt, setConditionPrompt] = useState<string>("")
  const [cardContent, setCardContent] = useState<string>("")
  const [saving, setSaving] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const navigate = useNavigate()

  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [staticCard, staticCardLoading, staticCardError] = useDocumentData(
    staticCardContentOid === "new"
      ? undefined
      : doc(
          db,
          GROUPS_COLLECTION,
          activeGroupOid,
          STATIC_CARD_CONTENT_COLLECTION,
          staticCardContentOid,
        ).withConverter(makeConverter<StaticCardContent>()),
  )

  useEffect(() => {
    if (staticCard) {
      setCardContent(staticCard.content)
      setConditionPrompt(staticCard.condition_prompt)
    }
  }, [staticCard])

  const saveCard = useCallback(async () => {
    if (!conditionPrompt || !cardContent) {
      handleError({
        error: new Error("content missing"),
        prefix: "Missing content, try refreshing or recreating the card",
      })
      return
    }
    setSaving(true)
    try {
      const data = {
        condition_prompt: conditionPrompt,
        content: cardContent,
        mimetype: "text/markdown" as const,
      }
      if (staticCardContentOid === "new") {
        // Create
        const newOid = await createStaticCard(authUser, activeGroupOid, data)
        navigate(`/live-assistant/cards/${newOid}`)
      } else {
        // Update
        await updateStaticCard(authUser, activeGroupOid, {
          oid: staticCardContentOid,
          ...data,
        })
      }

      handleSuccess("Card saved")
    } catch (error) {
      handleError({ error, prefix: "Couldn't save card" })
    } finally {
      setSaving(false)
    }
  }, [
    handleError,
    handleSuccess,
    conditionPrompt,
    cardContent,
    navigate,
    authUser,
    activeGroupOid,
    staticCardContentOid,
  ])

  const deleteCard = useCallback(async () => {
    setDeleting(true)
    try {
      await deleteStaticCard(activeGroupOid, staticCardContentOid)
      navigate("/live-assistant/cards")
    } catch (error) {
      handleError({ error, prefix: "Couldn't delete card" })
    } finally {
      setDeleting(false)
    }
  }, [handleError, activeGroupOid, navigate, staticCardContentOid])

  const liveUiCard: StaticContentUICard = {
    oid: "fake_oid",
    created_at: Timestamp.now() as FirebaseFirestore.Timestamp,
    dismissed: false,
    seen: true,
    kind: "STATIC_CONTENT",
    matched_text: "Example transcript text matching this card",
    body: {
      oid: staticCardContentOid,
      condition_prompt: conditionPrompt,
      content: cardContent,
      mimetype: "text/markdown",

      // Other fields
      created_at:
        staticCard?.created_at ??
        (Timestamp.now() as FirebaseFirestore.Timestamp),
      updated_at:
        staticCard?.created_at ??
        (Timestamp.now() as FirebaseFirestore.Timestamp),
      created_by: staticCard?.created_by ?? {
        uid: authUser.uid,
        email: authUser.email!,
      },
      last_updated_by: staticCard?.last_updated_by ?? {
        uid: authUser.uid,
        email: authUser.email!,
      },
    },
  }

  if (staticCardLoading) {
    return (
      <div className="flex gap-4 p-4">
        <Skeleton active loading />
        <Skeleton active loading />
      </div>
    )
  }
  if (!staticCard && staticCardContentOid !== "new") {
    return <div>Error: Card does not exist</div>
  }

  if (staticCardError) {
    return <div>Error: {staticCardError.message}</div>
  }

  const disabled = saving || deleting || staticCardLoading

  return (
    <div className="flex w-full grow flex-row justify-stretch gap-2">
      <div className="grow p-4">
        <Form layout="vertical" onFinish={saveCard}>
          <Form.Item
            label="Matching Condition"
            tooltip="When this condition occurs during a call, the card will be displayed.\nie Anybody mentions any of these competitors: ..."
            rules={[{ required: true }]}
          >
            <TextArea
              disabled={disabled}
              value={conditionPrompt}
              onChange={(e) => setConditionPrompt(e.target.value)}
              autoSize={{ minRows: 4, maxRows: 20 }}
            />
          </Form.Item>
          <Form.Item
            label="Card Contents (markdown)"
            tooltip="The card that will be shown when the matching condition occurs during a call"
            rules={[{ required: true }]}
          >
            <MarkdownEditor
              initialContent={staticCard?.content}
              onChange={setCardContent}
            />
          </Form.Item>
          <div className="flex gap-2">
            <Button
              type="primary"
              htmlType="submit"
              disabled={disabled}
              loading={saving}
            >
              {saving ? "Saving" : "Save"}
            </Button>
            <Popconfirm
              title="Delete the card"
              description="Are you sure to delete this card?"
              onConfirm={deleteCard}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="default"
                danger
                disabled={disabled}
                loading={saving}
              >
                {deleting ? "Deleting" : "Delete"}
              </Button>
            </Popconfirm>
          </div>
        </Form>
      </div>
      <div className="min-w-[400px] bg-gray-50 p-4 xl:min-w-[500px]">
        <h3 className="m-0 mb-4">Preview</h3>
        <LiveCard
          className="min-h-12"
          message={liveUiCard}
          showCardDetail={VOID_FUNCTION}
          onTogglePinCard={VOID_ASYNC_FUNCTION}
          onDismissCard={VOID_ASYNC_FUNCTION}
        />
      </div>
    </div>
  )
}

export default StaticCardEditor
