import { useLocalStorage } from "@uidotdev/usehooks"
import Button from "antd/es/button"
import type { User } from "firebase/auth"
import { collection, doc } from "firebase/firestore"
import { ListIcon, Rows3Icon, Table2Icon } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { EMPTY_ARRAY } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { GroupMembersProvider } from "../../hooks/useGroupMembers"
import {
  createAnswerQuestionnaireJobDownloadUrl,
  getAnswerQuestionnaireJobBaseDownloadUrl,
  reviewAnswerQuestionnaireJob,
} from "../../pages/QuestionnaireAssistant/api"
import { GROUPS_COLLECTION } from "../../types/common"
import type { StoredGeneratedAnsweredQuestion } from "../../types/jobs"
import type {
  AnswerQuestionnaireCellEdit,
  AnswerQuestionnaireJob,
} from "../../types/jobs"
import {
  ANSWER_QUESTIONNAIRE_JOBS_ANSWERS_SUBCOLLECTION,
  ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
  ANSWER_QUESTIONNAIRE_JOBS_EDITS_SUBCOLLECTION,
} from "../../types/jobs"
import Header from "../Header"
import NotFoundPage from "../NotFoundPage"
import FeedLoadingState from "./FeedLoading"
import GridLoading from "./GridLoading"
import QuestionnaireReviewFeedWrapper from "./QuestionnaireReviewFeedWrapper"
import QuestionnaireReviewSheetWrapper from "./QuestionnaireReviewSheetWrapper"

interface QuestionnaireReviewProps {
  user: User
  feedView?: boolean
}

const QuestionnaireReview: React.FC<QuestionnaireReviewProps> = ({
  feedView,
}) => {
  const { oid = "" } = useParams<{ oid: string }>()
  const [baseFileUrl, setBaseFileUrl] = useState("")
  const [baseFileError, setBaseFileError] = useState<Error>()
  const [downloading, setDownloading] = useState<boolean>()
  const [modifying, setModifying] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useLocalStorage<boolean>(
    "quilt__QuestionnaireReview__sidebarOpen",
    false,
  )
  const navigate = useNavigate()

  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const jobRef = doc(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
    oid,
  ).withConverter(makeConverter<AnswerQuestionnaireJob>())

  const jobAnswersSubColRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
    oid,
    ANSWER_QUESTIONNAIRE_JOBS_ANSWERS_SUBCOLLECTION,
  ).withConverter(makeConverter<StoredGeneratedAnsweredQuestion>())

  const cellEditsSubColRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
    oid,
    ANSWER_QUESTIONNAIRE_JOBS_EDITS_SUBCOLLECTION,
  ).withConverter(makeConverter<AnswerQuestionnaireCellEdit>())

  const [jobData, jobLoading, jobError] = useDocumentData(jobRef)

  // TODO(mgraczyk): Ideally we'd store edits locally and only fetch them once,
  // but if we do that the edits will disappear when we switch sheets.
  const [answerDocs, answersLoading, answersError] =
    useCollectionData(jobAnswersSubColRef)
  const [cellEdits, cellEditsLoading, cellEditsError] =
    useCollectionData(cellEditsSubColRef)

  const { handleError } = useErrorPopup()

  useEffect(() => {
    if (!oid) {
      return
    }
    const fetchUrl = async () => {
      try {
        const baseFileUrl = await getAnswerQuestionnaireJobBaseDownloadUrl(oid)
        setBaseFileUrl(baseFileUrl)
      } catch (error) {
        console.error(error)
        setBaseFileError(error as Error)
      }
    }
    void fetchUrl()
  }, [oid])

  const onDownload = useCallback(async () => {
    setDownloading(true)
    try {
      const downloadUrl = await createAnswerQuestionnaireJobDownloadUrl(oid)
      window.open(downloadUrl, "_parent", "noopener,noreferrer")
    } catch (error) {
      handleError({
        prefix: "Couldn't download answer questionnaire job",
        error,
      })
    } finally {
      setDownloading(false)
    }
  }, [oid, handleError])

  const onMarkComplete = useCallback(async () => {
    if (!oid) {
      return
    }
    setModifying(true)
    try {
      await reviewAnswerQuestionnaireJob(oid)
      navigate("/questionnaire-assistant")
    } catch (error) {
      console.error("Error reviewing job:", error)
      handleError({ error })
      setModifying(false)
    }
  }, [oid, handleError, navigate])

  const navigateView = useCallback(() => {
    navigate(
      feedView
        ? `/questionnaire-assistant/review/${oid}`
        : `/questionnaire-assistant/review/feed/${oid}`,
    )
  }, [navigate, oid, feedView])

  let headerContent: React.ReactNode | null = null
  let bodyContent: React.ReactNode
  if (jobLoading || answersLoading || cellEditsLoading) {
    bodyContent = feedView ? <FeedLoadingState /> : <GridLoading />
  } else if (jobError ?? answersError ?? cellEditsError) {
    console.error(
      "error loading questionnaire",
      jobError ?? answersError ?? cellEditsError,
    )
    bodyContent = <div>Error loading questionnaire</div>
  } else if (!jobData || jobData.state === "REMOVING") {
    bodyContent = <NotFoundPage noHeader />
  } else if (baseFileError) {
    bodyContent = <div>Could not load the questionnaire.</div>
  } else if (jobData.state === "DONE") {
    bodyContent = (
      <div>
        The questionnaire has already been completed and cannot be edited.
      </div>
    )
  } else if (jobData.state !== "REVIEWING") {
    bodyContent = (
      <div>Sorry, this questionnaire cannot be reviewed at this time</div>
    )
  } else if (!baseFileUrl) {
    bodyContent = feedView ? <FeedLoadingState /> : <GridLoading />
  } else {
    headerContent = (
      <div className="align-center flex justify-end">
        <Button
          className="text-primary mx-3 flex h-9 w-32 items-center justify-center rounded-md bg-purple-50 px-4 font-bold"
          title={feedView ? "Sheet View" : "List View"}
          onClick={navigateView}
          icon={feedView ? <Table2Icon /> : <Rows3Icon />}
        >
          {feedView ? "Sheet View" : "List View"}
        </Button>
        <Button
          className="text-primary flex h-9 w-32 items-center justify-center rounded-md bg-purple-50 px-4 font-bold"
          title="Download"
          onClick={onDownload}
          disabled={modifying || downloading}
          loading={modifying}
        >
          {downloading ? "Downloading..." : "Download"}
        </Button>

        <Button
          type="primary"
          title="Mark as Complete"
          className="w-42 ml-3 h-9 font-bold"
          onClick={onMarkComplete}
          disabled={modifying}
          loading={modifying}
        >
          Mark as Complete
        </Button>
        {!feedView && (
          <Button
            icon={<ListIcon />}
            className="ml-3 h-9 w-9 font-bold"
            onClick={() => setSidebarOpen((s) => !s)}
          />
        )}
      </div>
    )
    bodyContent = feedView ? (
      <QuestionnaireReviewFeedWrapper
        url={baseFileUrl}
        job={jobData}
        answers={answerDocs ?? EMPTY_ARRAY}
      />
    ) : (
      <QuestionnaireReviewSheetWrapper
        url={baseFileUrl}
        job={jobData}
        answers={answerDocs ?? EMPTY_ARRAY}
        edits={cellEdits ?? EMPTY_ARRAY}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    )
  }

  return (
    <>
      <Header
        title="Questionnaire Editor"
        breadcrumbs={[
          {
            title: "Questionnaire Assistant",
            href: "/questionnaire-assistant",
          },
          {
            title: jobData?.title ?? oid,
            href: `/questionnaire-assistant/review/${oid}`,
          },
        ]}
      >
        {headerContent}
      </Header>
      <div className="flex min-h-0 w-full grow justify-center pl-6">
        <GroupMembersProvider group_oid={activeGroupOid}>
          {bodyContent}
        </GroupMembersProvider>
      </div>
    </>
  )
}

export default QuestionnaireReview
