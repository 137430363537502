import { LockIcon } from "lucide-react"
import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import HelpButton from "../components/HelpButton"
import QuiltLogo from "../components/QuiltLogo"
import SSOForm from "../components/SSOForm"
import SignInButton from "../components/SignInButton"
import SocialLoginForm from "../components/SocialLoginForm"
import { PRIVACY_URL, TERMS_URL } from "../constants"

const FormTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span className="w-full px-2 pb-6 pt-5 text-center text-2xl font-bold">
    {children}
  </span>
)

const LegalSection: React.FC<{ actionText: string; className?: string }> = ({
  actionText,
  className = "",
}) => (
  <div className={className}>
    By clicking {actionText}, you agree to our{" "}
    <a
      className="text-primary font-medium hover:underline"
      href={TERMS_URL}
      target="_blank"
    >
      Terms
    </a>{" "}
    and{" "}
    <a
      className="text-primary font-medium hover:underline"
      href={PRIVACY_URL}
      target="_blank"
    >
      Privacy Policy
    </a>
  </div>
)

const SignInPage: React.FC = () => {
  const [errorString, setErrorString] = useState<string | undefined>()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { showNewMessage: showIntercom } = useIntercom()

  // Show intercom on error.
  useEffect(() => {
    if (errorString) {
      showIntercom(
        `I'm having trouble signing in. I got this error: "${errorString}"`,
      )
    }
  }, [errorString, showIntercom])

  const isSignup = pathname.includes("/signup")
  const isSSO = searchParams.has("sso")
  const actionText = isSignup ? "Sign up" : "Continue"

  let loginForm: React.ReactNode
  const ssoButtonText = `${actionText} with SSO`
  if (isSSO) {
    loginForm = (
      <>
        <FormTitle>{"What's your email address?"}</FormTitle>
        <SSOForm
          ssoButtonText={ssoButtonText}
          setErrorString={setErrorString}
        />
      </>
    )
  } else {
    loginForm = (
      <>
        <SocialLoginForm
          actionText={actionText}
          setErrorString={setErrorString}
        />
        <SignInButton
          logo={<LockIcon className="text-yellow-800" size={20} />}
          loading={false}
          buttonText={ssoButtonText}
          onClick={() => setSearchParams({ sso: "1" })}
        />
      </>
    )
  }

  return (
    <div className="grid h-full flex-row justify-stretch lg:flex lg:max-w-none">
      <div className="hidden w-1/2 flex-col items-start bg-purple-200 p-10 lg:flex">
        <QuiltLogo width="180px" />
        <div className="text-bold mt-auto text-2xl text-gray-800">
          Enterprise AI for Technical Sales
        </div>
      </div>
      <div className="flex min-w-0 grow flex-col items-center bg-gray-50 p-5 md:p-10">
        <div className="flex w-full max-w-[420px] grow flex-col justify-center">
          {!isSSO && <FormTitle>Welcome back</FormTitle>}
          <div className="m-x-auto flex w-full flex-col items-center space-y-4 md:p-5">
            {loginForm}
            {errorString && (
              <div className="text-center text-red-600">{errorString}</div>
            )}
          </div>
          <div className="mt-2 w-full flex-col text-center text-sm text-gray-600">
            <span>
              Need help?{" "}
              <HelpButton className="text-primary font-medium hover:underline">
                Contact Us
              </HelpButton>
            </span>
          </div>
        </div>
        <LegalSection actionText={actionText} className="mt-auto" />
      </div>
    </div>
  )
}

export default SignInPage
