export const UserFacingErrorType = {
  UNKNOWN: "UNKNOWN",
  ALREADY_EXISTS: "ALREADY_EXISTS",
  BAD_ANSWER_LOCATION: "BAD_ANSWER_LOCATION",
  CANCELLED: "CANCELLED",
  EXTERNAL_PERMISSION_DENIED: "EXTERNAL_PERMISSION_DENIED",
  INVALID_DOCUMENT: "INVALID_DOCUMENT",
  INVALID_STATE_FOR_ACTION: "INVALID_STATE_FOR_ACTION",
  MISSING_FIELD: "MISSING_FIELD",
  NOT_FOUND: "NOT_FOUND",
  NOT_YET_IMPLEMENTED: "NOT_YET_IMPLEMENTED",
  INVALID_ARGUMENT: "INVALID_ARGUMENT",
  NO_QUESTIONS_FOUND: "NO_QUESTIONS_FOUND",
  NO_SLACKBOT_CONFIGURATION_FOUND: "NO_SLACKBOT_CONFIGURATION_FOUND",
  NO_TEXT_FOUND: "NO_TEXT_FOUND",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNKNOWN_SHEET_LAYOUT: "UNKNOWN_SHEET_LAYOUT",
  UNSPECIFIED: "UNSPECIFIED",
  UNSUPPORTED_FILE_TYPE: "UNSUPPORTED_FILE_TYPE",
  USER_DISABLED: "USER_DISABLED",
  USER_HAS_NO_DOCUMENTS: "USER_HAS_NO_DOCUMENTS",
}

export type UserFacingErrorType = keyof typeof UserFacingErrorType
