import { createJob } from "../../pages/QuestionnaireAssistant/createJob"
import { isGoogleDriveDocument } from "./state"
import type { FileDocument, GoogleDriveDocument } from "./state"
import { getQuestionAnswerLayouts } from "./utils"

const createSheetsJob = async (doc: FileDocument | GoogleDriveDocument) => {
  if (isGoogleDriveDocument(doc)) {
    throw new Error("Google Sheets not supported")
  }

  if (
    doc.fileContents === undefined ||
    doc.fileType === undefined ||
    doc.fileName === undefined
  ) {
    throw new Error("No file")
  }

  const questionAnswerLayouts = getQuestionAnswerLayouts(doc)
  const jobUploadFileData = {
    fileContents: doc.fileContents,
    fileType: doc.fileType,
    fileName: doc.fileName,
    question_answer_layouts: questionAnswerLayouts,
  }
  await createJob(jobUploadFileData)
}

export default createSheetsJob
