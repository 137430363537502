import SalesforceLogo from "../assets/img/salesforce_logo.svg"
import type { CredentialMetadata } from "../credentials/providerTypes"
import IntegrationBlock from "./IntegrationBlock"

interface Props {
  loading: boolean
  credential: CredentialMetadata | undefined
  connectCredential: () => Promise<void>
  disconnectCredential: () => Promise<void>
  notEnabled?: boolean
}

const SalesforceIntegrationBlock: React.FC<Props> = ({
  loading,
  credential,
  connectCredential,
  disconnectCredential,
  notEnabled,
}) => {
  return (
    <IntegrationBlock
      loading={loading}
      name="Salesforce"
      connected={!!credential}
      onEdit={undefined}
      onConnect={connectCredential}
      onDisconnect={disconnectCredential}
      logo={SalesforceLogo}
      notEnabled={notEnabled}
    />
  )
}
export default SalesforceIntegrationBlock
