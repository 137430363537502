export const LIVE_ASSISTED_CALLS_COLLECTION = "live_assisted_calls"
export const LIVE_UI_CARDS_SUBCOLLECTION = "live_ui_cards"
export const LIVE_ASSISTED_CALLS_TRANSCRIPT_SEGMENTS_SUBCOLLECTION =
  "transcript_segments"

export const LIVE_ASSISTED_CALLS_LIVE_NOTES_SUBCOLLECTION = "live_notes"

// /group/{groupOid}/static_card_contents/{staticCardContentOid}
export const STATIC_CARD_CONTENT_COLLECTION = "static_card_contents"

// /group/{groupOid}/live_notes_templates/{liveNotesTemplateOid}
export const LIVE_NOTES_TEMPLATE_SUBCOLLECTION = "live_notes_templates"
