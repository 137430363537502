import type { User as AuthUser } from "firebase/auth"
import { doc, updateDoc } from "firebase/firestore"
import {
  AtSign,
  Check,
  Files,
  InfoIcon,
  List,
  MessageCircle,
  Sheet,
  UserRoundCheck,
} from "lucide-react"
import { useCallback } from "react"
import { Link } from "react-router-dom"

import { db } from "../firebaseApp"
import type { Notification } from "../notifications/types"
import { getRelativeTimeString } from "../timeUtils"
import { USERS_COLLECTION } from "../types/common"
import Menu from "./Menu"

interface Props {
  user: AuthUser
  notification: Notification
}

function getNotificationUrl(notification: Notification): string | null {
  if (notification.url?.startsWith("http")) {
    return notification.url
  } else if (notification.url?.startsWith("/")) {
    return notification.url
  } else if (notification.url) {
    return `/${notification.url}`
  } else if (notification.kind === "QUESTIONNAIRE_JOB_COMPLETED") {
    return `/questionnaire-assistant/review/${notification.questionnaire_oid}`
  } else if (
    notification.kind === "DISCUSSION_ASSIGNED" ||
    notification.kind === "DISCUSSION_MENTIONED" ||
    notification.kind === "DISCUSSION_RESOLVED"
  ) {
    if (notification.context.kind === "QUESTIONNAIRE") {
      return `/questionnaire-assistant/review/${notification.context.questionnaire_oid}?sheetName=${notification.context.location.sheetName}&firstRowIndex=${notification.context.location.firstRowIndex}&firstColIndex=${notification.context.location.firstColIndex}&discussion_oid=${notification.discussion_oid}`
    } else if (notification.context.kind === "KNOWLEDGE_ITEM") {
      return `/knowledge-items/${notification.context.knowledge_item_oid}?discussion_oid=${notification.discussion_oid}`
    } else if (notification.context.kind === "USER_DOCUMENT") {
      return `/source-documents/${notification.context.user_document_oid}?discussion_oid=${notification.discussion_oid}`
    }
  }
  if (
    notification.kind === "KNOWLEDGE_ITEM_REVIEWED" ||
    notification.kind === "KNOWLEDGE_ITEM_ASSIGNED"
  ) {
    return `/knowledge-items/${notification.knowledge_item_oid}`
  }
  if (
    notification.kind === "USER_DOCUMENT_REVIEWED" ||
    notification.kind === "USER_DOCUMENT_ASSIGNED"
  ) {
    return notification.is_knowledge_item
      ? `/knowledge-items/${notification.user_document_oid}`
      : `/source-documents/${notification.user_document_oid}`
  }

  return null
}

const NotificationIcon: React.FC<{ notification: Notification }> = ({
  notification,
}) => {
  const { kind } = notification
  let icon
  let subIcon = null
  if (kind === "DISCUSSION_ASSIGNED") {
    icon = <MessageCircle />
    subIcon = (
      <AtSign
        width={16}
        height={16}
        className="rounded-[16px] bg-blue-500 p-[2px] text-white"
      />
    )
  } else if (kind === "DISCUSSION_MENTIONED") {
    icon = <MessageCircle />
    subIcon = (
      <Check
        width={16}
        height={16}
        className="rounded-[16px] bg-green-500 p-[2px] text-white"
      />
    )
  } else if (kind === "DISCUSSION_RESOLVED") {
    icon = <MessageCircle />
    subIcon = (
      <UserRoundCheck
        width={16}
        height={16}
        className="rounded-[16px] bg-green-500 p-[2px] text-white"
      />
    )
  } else if (kind === "KNOWLEDGE_ITEM_REVIEWED") {
    icon = <List />
    subIcon = (
      <Check
        width={16}
        height={16}
        className="rounded-[16px] bg-green-500 p-[2px] text-white"
      />
    )
  } else if (kind === "KNOWLEDGE_ITEM_ASSIGNED") {
    icon = <List />
    subIcon = (
      <AtSign
        width={16}
        height={16}
        className="rounded-[16px] bg-blue-500 p-[2px] text-white"
      />
    )
  } else if (kind === "USER_DOCUMENT_REVIEWED") {
    icon = notification.is_knowledge_item ? <List /> : <Files />
    subIcon = (
      <Check
        width={16}
        height={16}
        className="rounded-[16px] bg-green-500 p-[2px] text-white"
      />
    )
  } else if (kind === "USER_DOCUMENT_ASSIGNED") {
    icon = notification.is_knowledge_item ? <List /> : <Files />
    subIcon = (
      <AtSign
        width={16}
        height={16}
        className="rounded-[16px] bg-blue-500 p-[2px] text-white"
      />
    )
  } else if (kind === "QUESTIONNAIRE_JOB_COMPLETED") {
    icon = <Sheet />
    subIcon = (
      <Check
        width={16}
        height={16}
        className="rounded-[16px] bg-green-500 p-[2px] text-white"
      />
    )
  } else {
    icon = <InfoIcon />
  }
  return (
    <div className="bg-gray-25 relative flex h-[40px] w-[40px] items-center justify-center rounded-[40px] text-gray-600">
      {icon}
      {subIcon && <div className="absolute bottom-0 right-0">{subIcon}</div>}
    </div>
  )
}

const NotificationItem: React.FC<Props> = ({ user, notification }) => {
  const { uid } = user
  const { oid } = notification

  const markAsSeen = useCallback(async () => {
    const notificationRef = doc(db, USERS_COLLECTION, uid, "notifications", oid)
    await updateDoc(notificationRef, {
      seen: true,
    })
  }, [uid, oid])

  const markAsAcked = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      if (notification.acked) {
        return
      }
      if (e.currentTarget.hasAttribute("data-preventdefault")) {
        e.preventDefault()
      }

      const notificationRef = doc(
        db,
        USERS_COLLECTION,
        uid,
        "notifications",
        oid,
      )
      await updateDoc(notificationRef, {
        acked: true,
      })
    },
    [uid, oid, notification.acked],
  )

  const inner = (
    <div className="flex items-center">
      <div className="w-[12px]">
        {!notification.acked && (
          <div
            className="h-2 w-2 cursor-pointer rounded-full bg-purple-500 shadow"
            data-preventdefault
            onClick={markAsAcked}
          />
        )}
      </div>
      <NotificationIcon notification={notification} />
      <div className="ml-3">
        <div className="text-sm font-medium">{notification.text}</div>
        <div
          className="text-xs text-gray-500"
          title={notification.created_at.toDate().toLocaleString()}
        >
          {getRelativeTimeString(notification.created_at)}
        </div>
      </div>
      <div className="mb-auto ml-auto">&nbsp;</div>
      <div
        className="ml-2"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <Menu user={user} notification={notification} />
      </div>
    </div>
  )

  const className =
    "block pl-2 pr-6 py-2 rounded-lg hover:bg-gray-25 border border-gray-100"
  const url = getNotificationUrl(notification)
  if (url?.startsWith("http")) {
    return (
      <a
        href={url}
        className={className}
        target="_blank"
        rel="noreferrer noopener"
        onClick={markAsAcked}
        onMouseEnter={notification.seen ? undefined : markAsSeen}
      >
        {inner}
      </a>
    )
  } else if (url) {
    return (
      <Link
        to={url}
        className={className}
        target="_blank"
        rel="noreferrer noopener"
        onClick={markAsAcked}
        onMouseEnter={notification.seen ? undefined : markAsSeen}
      >
        {inner}
      </Link>
    )
  } else {
    return (
      <div
        className={className + (notification.acked ? "" : " cursor-pointer")}
        onClick={markAsAcked}
        onMouseEnter={notification.seen ? undefined : markAsSeen}
      >
        {inner}
      </div>
    )
  }
}

export default NotificationItem
