import LoadingSpinner from "./LoadingSpinner"

const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
  const missingJsErrorPattern = "failed to fetch dynamically imported module"
  if (error.message.toLowerCase().includes(missingJsErrorPattern)) {
    console.warn("missing js error", error)

    // Only refresh if the last time we did it was more than 1 minutes ago
    const lastRefreshOnError = localStorage.getItem("quilt__lastRefreshOnError")
    const now = Date.now()
    if (!lastRefreshOnError || now > parseInt(lastRefreshOnError) + 60000) {
      localStorage.setItem("quilt__lastRefreshOnError", now.toString())
      window.location.reload()
      return <LoadingSpinner />
    }
  }

  // TODO(mgraczyk): Figure out how to reset boundary without breaking things.
  return (
    <div role="alert">
      <p>
        Something went wrong: Please refresh the page or contact support at
        support@quilt.app
      </p>
    </div>
  )
}
export default ErrorFallback
