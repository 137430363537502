import Button from "antd/es/button"
import Divider from "antd/es/divider"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Tooltip from "antd/es/tooltip"
import { PlusIcon, SparklesIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { sendAnalyticsEvent } from "../analytics"
import { getAnswer } from "../api"
import useErrorPopup from "../hooks/useErrorPopup"
import { createKnowledgeItemApi } from "../knowledge/api"

interface Props {
  onClose: () => void
}

interface FormDataType {
  primary_question: string
  secondary_question: string
  primary_answer: string
  secondary_answer: string
}

const AddKnowledgeItemForm: React.FC<Props> = ({ onClose }) => {
  const [form] = Form.useForm<FormDataType>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [autoAnswering, setAutoAnswering] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const primaryQuestion = Form.useWatch("primary_question", form)
  const primaryAnswer = Form.useWatch<string>("primary_answer", form)

  const autoAnswer = useCallback(async () => {
    setAutoAnswering(true)
    const questionText = form.getFieldValue("primary_question") as string
    try {
      const response = await getAnswer({
        question: {
          text: questionText,
          details: (form.getFieldValue("secondary_question") as string) || null,
          location: null,
          details_location: null,
        },
        analyticsProduct: "WEB",
        analyticsSurface: "WEB_KNOWLEDGE_REPOSITORY",
      })
      void sendAnalyticsEvent({
        surface: "WEB_KNOWLEDGE_REPOSITORY",
        event_type: "AUTOGENERATE_ANSWER",
        event_data: {
          entity_type: "KNOWLEDGE_ITEM",
          entity_id: null,
          answerer_request_id: response.request.request_id,
        },
      })

      // Update the answer in the form.
      form.setFieldValue("primary_answer", response.answer.primary_answer)

      handleSuccess("Automatically generated answer")
    } catch (error: unknown) {
      handleError({ error, prefix: "Couldn't generate answer" })
    } finally {
      setAutoAnswering(false)
    }
  }, [handleSuccess, handleError, form])

  const onSubmit = useCallback(
    async (formData: FormDataType) => {
      setSubmitting(true)
      try {
        const {
          data: { oid: knowledgeItemOid },
        } = await createKnowledgeItemApi({
          question: {
            primary: formData.primary_question,
            secondary: formData.secondary_question || undefined,
          },
          answer: {
            primary: formData.primary_answer,
            secondary: formData.secondary_answer || undefined,
          },
        })
        void sendAnalyticsEvent({
          surface: "WEB_KNOWLEDGE_REPOSITORY",
          event_type: "CREATE_ENTITY",
          event_data: {
            entity_type: "KNOWLEDGE_ITEM",
            entity_id: knowledgeItemOid,
          },
        })

        form.resetFields()
        handleSuccess("Added answer to Answer Bank")
        onClose()
      } catch (error: unknown) {
        handleError({ error, prefix: "Couldn't save answer" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, form, onClose],
  )

  const autoSize = { minRows: 1, maxRows: 8 }

  // TODO(mgracyzyk): Switch to taking the categorical input explicitly and
  // determining the answer type based on which fields have been filled out.
  // Also change the knowledge item page form to match.
  return (
    <Form
      form={form}
      layout="vertical"
      disabled={submitting}
      style={{ width: 800 }}
      requiredMark="optional"
      onFinish={onSubmit}
    >
      <Form.Item
        label="Question"
        required
        name="primary_question"
        rules={[{ required: true }]}
      >
        <Input.TextArea autoSize={autoSize} />
      </Form.Item>
      <Form.Item label="Question Details" name="secondary_question">
        <Input.TextArea autoSize={autoSize} />
      </Form.Item>
      <Divider />

      <div className="mb-[24px] flex flex-row items-end">
        <Form.Item
          shouldUpdate={true}
          label="Answer (Short Form or Multiple Choice)"
          required
          name="primary_answer"
          rules={[{ required: true }]}
          className="mb-0 flex-grow"
        >
          <Input.TextArea autoSize={autoSize} className="rounded-md" />
        </Form.Item>
        <Tooltip
          /* TODO(mgraczyk): Hack to make this work on a modal. */
          zIndex={2000}
          title="Automatically generate an answer with AI"
        >
          <Button
            type="default"
            onClick={autoAnswer}
            className="text-primary ml-2 flex bg-purple-50"
            htmlType="button"
            disabled={!primaryQuestion || submitting || autoAnswering}
          >
            <SparklesIcon />
          </Button>
        </Tooltip>
      </div>
      <Form.Item label="Explanation of Answer" name="secondary_answer">
        <Input.TextArea
          disabled={!primaryAnswer || submitting || autoAnswering}
          autoSize={autoSize}
        />
      </Form.Item>
      <div className="flex items-center justify-end">
        <Button
          type="primary"
          disabled={submitting}
          className="submit font-bold"
          htmlType="submit"
          icon={<PlusIcon height={16} />}
        >
          {submitting ? "Creating..." : "Create"}
        </Button>
      </div>
    </Form>
  )
}

export default AddKnowledgeItemForm
