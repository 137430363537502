import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Select from "antd/es/select"
import type { User as AuthUser } from "firebase/auth"
import { collection, limit, query } from "firebase/firestore"
import { useCallback, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import liveImgSrc from "../../assets/img/live.jpg"
import Header from "../../components/Header"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { useActiveGroup } from "../../contexts/useActiveGroup"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import LiveAssistedCallsList from "../../live/LiveAssistedCallsList"
import { createLiveAssistedCall } from "../../live/api"
import type { Playbook } from "../../live/playbook/types"
import { PLAYBOOKS_SUBCOLLECTION } from "../../live/playbook/types"
import { GROUPS_COLLECTION } from "../../types/common"

interface FormData {
  contextForPrompt: string
  boostWords: string
  playbookOid?: string
}

const StartWebLiveAssistantForm: React.FC = () => {
  const [form] = Form.useForm<FormData>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError, messageApi } = useErrorPopup()
  const navigate = useNavigate()

  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const { flags, flagsLoading } = useActiveGroup()

  const [playbooks, loadingPlaybooks] = useCollectionData<Playbook>(
    query(
      collection(
        db,
        GROUPS_COLLECTION,
        activeGroupOid,
        PLAYBOOKS_SUBCOLLECTION,
      ).withConverter(makeConverter<Playbook>()),
      limit(1000),
    ),
  )

  const onFinish = useCallback(
    async (formData: FormData) => {
      try {
        if (submitting) {
          return
        }

        setSubmitting(true)
        void messageApi.open({ type: "info", content: "Creating assistant..." })
        const liveAssistedCall = await createLiveAssistedCall({
          meeting_type: "WEB",
          context_for_prompt: formData.contextForPrompt,
          boost_words: (formData.boostWords || "")
            .split(",")
            .map((w) => w.trim())
            .filter(Boolean),
          playbook_oid: formData?.playbookOid || null,
        })
        console.log("Created call", liveAssistedCall)
        handleSuccess("Created! Click Play to start the call.")
        navigate(`/live-assistant/${liveAssistedCall.oid}`)
      } catch (error) {
        handleError({ error, prefix: "Could not create assistant" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, messageApi, navigate, submitting],
  )

  const initialValues: FormData = {
    contextForPrompt: "",
    boostWords: "",
  }

  if (flagsLoading) return null

  const formUI = (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      className="mb-12 max-w-[800px]"
    >
      <Form.Item name="boostWords" label="Words to boost">
        <Input
          disabled={submitting}
          title="Words to boost (use comma to separate)"
          placeholder="Quilt, SOC-2"
        />
      </Form.Item>
      <Form.Item
        name="contextForPrompt"
        label="Context for prompt"
        rules={[{ message: "Please input context for prompt" }]}
      >
        <Input.TextArea
          disabled={submitting}
          title="Context for prompt"
          placeholder="Quilt is a secure platform for data sharing. This call is about..."
        />
      </Form.Item>
      {flags.showPlaybooks && (
        <Form.Item
          name="playbookOid"
          label="Playbook"
          tooltip={
            playbooks?.length === 0
              ? 'No playbook found. Click "View Playbook" in the top right to add one.'
              : "Select the playbook."
          }
        >
          <Select
            loading={loadingPlaybooks}
            placeholder={
              loadingPlaybooks
                ? "Loading..."
                : playbooks?.length === 0
                  ? "No playbooks found"
                  : "Playbook name..."
            }
            allowClear
            disabled={submitting || loadingPlaybooks || playbooks?.length === 0}
            options={
              playbooks?.map((playbook) => ({
                label: playbook.name,
                value: playbook.oid,
              })) ?? []
            }
          />
        </Form.Item>
      )}
      <Form.Item>
        <Button
          type="primary"
          className="w-26"
          disabled={submitting}
          htmlType="submit"
        >
          {submitting ? "Creating..." : "Create Call"}
        </Button>
      </Form.Item>
    </Form>
  )

  return (
    <>
      <div className="mb-8 flex w-full max-w-[600px] items-center">
        <img
          className="h-auto w-[96px] rounded-3xl"
          src={liveImgSrc}
          alt="Live Assistant"
          width={1024}
          height={1024}
        />
        <div>
          <div className="ml-4 text-lg font-semibold">
            Try the new Quilt Live Assistant
          </div>
          <div className="text-md ml-4">
            The assistant will listen to your call and answer questions it
            hears.
          </div>
        </div>
      </div>
      {formUI}
    </>
  )
}

const WebLiveAssistantPage: React.FC<{ user: AuthUser }> = ({ user }) => {
  const { flags } = useActiveGroup()

  return (
    <>
      <Header title="Live Assistant">
        {flags.showPlaybooks && (
          <Link to="/live-assistant/playbooks">
            <Button type="default" className="mr-1">
              View/Edit Playbooks
            </Button>
          </Link>
        )}
        <Link to="/live-assistant/cards">
          <Button type="default">View/Edit Cards</Button>
        </Link>
      </Header>
      <div className="max-w-[1200px] p-4">
        <StartWebLiveAssistantForm />
        <LiveAssistedCallsList user={user} />
      </div>
    </>
  )
}

export default WebLiveAssistantPage
