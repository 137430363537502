import Skeleton from "antd/es/skeleton"
import Tabs from "antd/es/tabs"
import type { User as AuthUser } from "firebase/auth"
import { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"

import BillingPage from "../../billing/BillingPage"
import Header from "../../components/Header"
import { VOID_FUNCTION } from "../../constants"
import { useActiveGroup } from "../../contexts/useActiveGroup"
import IntegrationsManager from "../../integrations/IntegrationsManager"
import EditGroupForm from "./EditGroupForm"
import GroupImage from "./GroupImage"
import GroupReportingPage from "./GroupReportingPage"
import MembersTab from "./MembersTab"
import SSOSettingsForm from "./SSOSettingsForm"
import SettingsTab from "./SettingsTab"
import TagsTab from "./TagsTab"

interface Props {
  user: AuthUser
}

const GroupSettingsPage: React.FC<Props> = () => {
  const { settingsPage: activeTabKey } = useParams()
  const navigate = useNavigate()
  const { authUser, activeGroup, activeGroupLoading, hasPerm } =
    useActiveGroup()

  const onTabChange = useCallback(
    (key: string) => {
      navigate(`/group/settings/${key}`)
    },
    [navigate],
  )

  let body: React.ReactNode
  if (activeGroupLoading || !activeGroup) {
    body = <Skeleton className="m-12 w-full" active loading />
  } else {
    const tabItems = [
      {
        label: "Basic Info",
        key: "info",
        children: (
          <EditGroupForm onSubmit={VOID_FUNCTION} group={activeGroup} />
        ),
      },
      {
        label: "Advanced Settings",
        key: "advanced",
        children: <SettingsTab group={activeGroup} />,
      },
      {
        label: "Tags",
        key: "tags",
        children: <TagsTab activeGroup={activeGroup} hasPerm={hasPerm} />,
      },
      {
        label: "Members",
        key: "members",
        children: <MembersTab user={authUser} activeGroup={activeGroup} />,
      },
      {
        label: "Integrations",
        key: "integrations",
        children: <IntegrationsManager />,
      },
    ]
    if (hasPerm("admin")) {
      tabItems.push({
        label: "Reporting",
        key: "reporting",
        children: <GroupReportingPage />,
      })
      tabItems.push({
        label: "Billing",
        key: "billing",
        children: <BillingPage />,
      })
      if (activeGroup.domain_names?.length) {
        // Domain name verification is required for SSO for security.
        tabItems.push({
          label: "SSO",
          key: "sso",
          children: <SSOSettingsForm group={activeGroup} />,
        })
      }
    }
    body = (
      <Tabs
        activeKey={activeTabKey ?? "info"}
        onChange={onTabChange}
        type="card"
        items={tabItems}
      />
    )
  }

  return (
    <>
      <Header title="Manage Group Settings" />
      <div className="p-4">
        <div className="my-4 flex items-center justify-between gap-4">
          <Skeleton
            className="max-w-96"
            avatar={{ size: 96 }}
            active
            loading={!activeGroup}
            paragraph={{ rows: 1 }}
          >
            <div className="flex items-center gap-4">
              <GroupImage imageUrl={activeGroup?.image_url} />
              <div>
                <div className="text-sm leading-none text-gray-500">GROUP</div>
                <div className="text-lg font-bold">{activeGroup?.name}</div>
              </div>
            </div>
          </Skeleton>
        </div>
        <hr className="my-6 border border-slate-100" />
        {body}
      </div>
    </>
  )
}

export default GroupSettingsPage
