import type { User as AuthUser } from "firebase/auth"
import { createContext, useContext, useMemo } from "react"

import { type QuiltPermission, RolePermissions } from "../auth/permissions"
import { useQuiltAuthState } from "./useQuiltAuthState"

interface ActiveUserAuthorizationContextType {
  authUser: AuthUser
  activeGroupOid: string
  hasPerm: (permission: QuiltPermission) => boolean
  isDisabled: boolean
  numGroups: number
}

const ActiveUserAuthorizationContext =
  createContext<ActiveUserAuthorizationContextType>({
    // NOTE: We check auth user and activeGroupOid at runtime.
    authUser: {} as AuthUser,
    activeGroupOid: "",
    hasPerm: () => false,
    isDisabled: false,
    numGroups: 0,
  })

const ActiveUserAuthorizationProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { authUser, claims } = useQuiltAuthState()
  if (!authUser || !claims || !claims.activeGroupOid || !claims.roles) {
    throw new Error("User should be logged in before using this provider.")
  }

  const value = useMemo(() => {
    const activeGroupOid = claims.activeGroupOid
    const groupRole = claims.quiltAdmin
      ? "QUILT_ADMIN"
      : claims.roles[activeGroupOid]
    const hasPerm = (permission: QuiltPermission) =>
      permission === "quiltAdmin"
        ? !!claims.quiltAdmin
        : RolePermissions[groupRole].includes(permission)

    return {
      authUser,
      activeGroupOid,
      hasPerm,
      isDisabled: !!claims.isDisabled,
      numGroups: Object.keys(claims.roles).length,
    }
  }, [authUser, claims])

  return (
    <ActiveUserAuthorizationContext.Provider value={value}>
      {children}
    </ActiveUserAuthorizationContext.Provider>
  )
}

const useActiveUserAuthorizationFromContext =
  (): ActiveUserAuthorizationContextType => {
    return useContext(ActiveUserAuthorizationContext)
  }

export {
  ActiveUserAuthorizationProvider,
  useActiveUserAuthorizationFromContext,
}
