import type { User as AuthUser } from "firebase/auth"
import { serverTimestamp } from "firebase/firestore"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore"

import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { GROUPS_COLLECTION } from "../types/common"
import { STATIC_CARD_CONTENT_COLLECTION } from "./db"
import type { StaticCardContent } from "./types"

type CardMutation = Omit<
  StaticCardContent,
  "oid" | "created_at" | "updated_at" | "created_by" | "last_updated_by"
>

const colRef = (groupOid: string) =>
  collection(
    db,
    GROUPS_COLLECTION,
    groupOid,
    STATIC_CARD_CONTENT_COLLECTION,
  ).withConverter(makeConverter<StaticCardContent>())

// Returns the oid of the newly created card.
export const createStaticCard = async (
  authUser: AuthUser,
  groupOid: string,
  card: CardMutation,
): Promise<string> => {
  const newCard = {
    ...card,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    created_by: {
      uid: authUser.uid,
      email: authUser.email,
    },
    last_updated_by: {
      uid: authUser.uid,
      email: authUser.email,
    },
  }
  const result = await addDoc(colRef(groupOid), newCard)

  return result.id
}

export const updateStaticCard = async (
  authUser: AuthUser,
  groupOid: string,
  card: CardMutation & { oid: string },
): Promise<void> => {
  const updates = {
    ...card,
    updated_at: serverTimestamp(),
    last_updated_by: {
      uid: authUser.uid,
      email: authUser.email!,
    },
  }

  await updateDoc(doc(colRef(groupOid), card.oid), updates)
}

export const deleteStaticCard = async (
  groupOid: string,
  cardOid: string,
): Promise<void> => {
  await deleteDoc(doc(colRef(groupOid), cardOid))
}
