import Skeleton from "antd/es/skeleton"
import {
  addDoc,
  collection,
  limit,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore"
import { useEffect, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useDebounceCallback } from "usehooks-ts"

import MarkdownEditor from "../../components/MarkdownEditor"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import {
  LIVE_ASSISTED_CALLS_COLLECTION,
  LIVE_ASSISTED_CALLS_LIVE_NOTES_SUBCOLLECTION,
} from "../../live/db"
import type { LiveNotes } from "../../live/types"

interface Props {
  callOid: string
  className?: string
}

const colRef = (callOid: string) =>
  collection(
    db,
    LIVE_ASSISTED_CALLS_COLLECTION,
    callOid,
    LIVE_ASSISTED_CALLS_LIVE_NOTES_SUBCOLLECTION,
  ).withConverter(makeConverter<LiveNotes>())

// Returns the oid of the newly created card.
export const createNotesVersion = async (
  callOid: string,
  prevVersion: LiveNotes,
  content: string,
): Promise<string> => {
  const newVersion = {
    created_at: serverTimestamp(),
    template_oid: prevVersion.template_oid,
    name: prevVersion.name,
    creator_source: "USER",
    content,
  }
  const result = await addDoc(colRef(callOid), newVersion)

  return result.id
}

// Get the LiveNotes for the callOid using firestore hooks
const Notetaker: React.FC<Props> = ({ callOid, className = "" }) => {
  const { handleError } = useErrorPopup()
  const [liveNotes, liveNotesLoading] = useCollectionData(
    query(colRef(callOid), orderBy("created_at", "desc"), limit(1)),
  )
  const [content, setContent] = useState<string>()

  const latestLiveNote = liveNotes?.[0]

  useEffect(() => {
    if (!latestLiveNote) {
      setContent(undefined)
      return
    }
    setContent((c) =>
      c === undefined || latestLiveNote.creator_source === "SYSTEM"
        ? latestLiveNote.content
        : c,
    )
  }, [latestLiveNote])

  const onChange = useDebounceCallback(async (content: string) => {
    if (!latestLiveNote) {
      return
    }

    // Update the live note content in the database.
    try {
      await createNotesVersion(callOid, latestLiveNote, content)
    } catch (error) {
      handleError({ error, prefix: "Couldn't save notes" })
    }
  }, 1000)

  return (
    <div className={"w-full overflow-y-scroll " + className}>
      <Skeleton
        title={false}
        loading={liveNotesLoading || content === undefined}
        active
      >
        <MarkdownEditor
          onChange={onChange}
          className=""
          initialContent={content}
        />
      </Skeleton>
    </div>
  )
}

export default Notetaker
