import Button from "antd/es/button"
import List from "antd/es/list"
import Upload from "antd/es/upload"
import { ArrowRightIcon, CloudUploadIcon, TrashIcon } from "lucide-react"
import { useCallback, useEffect, useState } from "react"

import { sendAnalyticsEvent } from "../analytics"
import { createUserDocuments } from "../api"
import { EMPTY_ARRAY } from "../constants"
import type { FileData } from "../documents/types"
import uploadUserFile from "../files/uploadUserFile"
import useErrorPopup from "../hooks/useErrorPopup"
import {
  MimeType,
  QUESTIONNAIRE_SUPPORTED_MIMETYPES,
  SOURCE_DOCUMENT_SUPPORTED_MIMETYPES,
  getMimeTypeFromFilename,
} from "../types/mimetype"
import { QuestionnaireWorkflowWizardFromFiles } from "./QuestionnaireWorkflowWizard"
import indexDocument from "./QuestionnaireWorkflowWizard/indexDocument"

const { Dragger } = Upload

interface Props {
  onClose: () => void
  isCompletedQuestionnaireActions: boolean
}

const UploadUserDocumentForm: React.FC<Props> = ({
  onClose,
  isCompletedQuestionnaireActions,
}) => {
  const [uploading, setUploading] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const [completedQuestionnaireFiles, setCompletedQuestionnaireFiles] =
    useState<FileData[]>([])
  const [showImportWizard, setShowImportWizard] = useState<boolean>(false)
  const { messageApi, handleError, handleSuccess } = useErrorPopup()

  const buttonSubText = isCompletedQuestionnaireActions
    ? "Upload a completed questionnaire and we will import the answers. Only Excel files and Sheets are currently supported. Other documents should be uploaded as Source Documents."
    : "Upload a document and we will parse it to answer questions. Spreadsheets will be interpreted as completed questionnaires."

  useEffect(() => {
    if (completedQuestionnaireFiles.length > 0) {
      setShowImportWizard(true)
    }
  }, [completedQuestionnaireFiles])

  const submitFiles = useCallback(
    async (files: File[]) => {
      void messageApi.open({
        type: "info",
        content: "Uploading files...",
        key: "uploading-files",
        duration: 10,
      })
      try {
        setUploading(true)
        await Promise.allSettled(
          files.map(async (file) => {
            try {
              // Do not index XLSX files as these will be handled with the
              // import wizard
              // TODO(mgraczyk): Check isCompletedQuestionnaireActions here once
              // the UI indicates what is and is not a completed questionnaire.
              if (
                QUESTIONNAIRE_SUPPORTED_MIMETYPES.includes(
                  file.type as MimeType,
                )
              ) {
                const fileContents = new Uint8Array(await file.arrayBuffer())
                setCompletedQuestionnaireFiles((prevFiles) => [
                  ...prevFiles,
                  {
                    fileContents,
                    fileType: file.type as MimeType,
                    fileName: file.name,
                  },
                ])
                return
              }

              // TODO(mgraczyk): Show upload progress.
              const { id, uri } = await uploadUserFile(
                file,
                "source_document_uploads",
                file.type as MimeType,
                file.name,
              )
              await createUserDocuments([
                {
                  external_document_id: id,
                  url: "",
                  source_kind: "FILEUPLOAD",
                  uploaded_file_uri: uri,
                  title: file.name,
                  mimetype: file.type as MimeType,
                },
              ])

              void sendAnalyticsEvent({
                surface: "WEB_SOURCE_DOCUMENTS",
                event_type: "CREATE_ENTITY",
                event_data: {
                  entity_type: "SOURCE_DOCUMENT_FILE",
                  entity_id: id,
                },
              })
            } catch (error) {
              handleError({
                error,
                prefix: `Error uploading file: ${file.name}`,
              })
              // TODO(mgraczyk): Show error.
            }
          }),
        )
        handleSuccess("Files uploaded successfully")
      } catch (error) {
        handleError({ error, prefix: "Can't upload files" })
      } finally {
        setFiles([])
        setUploading(false)
        void messageApi.destroy("uploading-files")
      }
    },
    [messageApi, handleSuccess, handleError],
  )

  const handleFileChange = useCallback(
    (file: File): boolean => {
      if (file === undefined) {
        handleError({ message: "No file selected" })
        return false
      }
      if (file.type === "") {
        const mimeType = getMimeTypeFromFilename(file.name)
        if (mimeType !== MimeType.UNKNOWN) {
          file = new File([file], file.name, { type: mimeType })
        }
      }
      const fileType = file.type as MimeType

      const supportedMimeTypes = isCompletedQuestionnaireActions
        ? QUESTIONNAIRE_SUPPORTED_MIMETYPES
        : SOURCE_DOCUMENT_SUPPORTED_MIMETYPES
      if (!supportedMimeTypes.includes(fileType)) {
        handleError({
          message: "Invalid file type. Please upload a supported file type",
        })
        return false
      }
      setFiles((prevFiles) => [...prevFiles, file])
      return false
    },
    [handleError, isCompletedQuestionnaireActions],
  )

  const uploadForm = (
    <div className="m-4">
      <Dragger
        className="h-full"
        beforeUpload={handleFileChange}
        showUploadList={false}
        multiple={true}
      >
        <div className="p-2">
          <CloudUploadIcon className="text-primary mx-auto my-4" size="48" />
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">{buttonSubText}</p>
        </div>
      </Dragger>
      {files.length > 0 && (
        <List className="mb-4 mt-2">
          {files.map((file, index) => (
            <List.Item
              className="bg-gray-25 my-1 flex justify-between rounded-md !border-0 !py-[4px] !pl-[12px] !pr-[8px]"
              key={index}
            >
              <span className="text-sm">{file.name}</span>
              <Button
                type="text"
                onClick={() =>
                  setFiles((files) => files.filter((_, idx) => idx !== index))
                }
                icon={<TrashIcon size="1em" />}
              />
            </List.Item>
          ))}
        </List>
      )}
      <div className="mt-4 flex justify-end">
        <Button
          type="primary"
          disabled={files.length === 0 || uploading}
          onClick={async () => {
            await submitFiles(files)
            onClose()
          }}
          icon={<ArrowRightIcon size="1em" />}
          className="font-bold"
        >
          Continue
        </Button>
      </div>
    </div>
  )

  return (
    <>
      {showImportWizard && (
        <>
          <QuestionnaireWorkflowWizardFromFiles
            onClose={() => {
              setShowImportWizard(false)
              setCompletedQuestionnaireFiles(EMPTY_ARRAY)
            }}
            files={completedQuestionnaireFiles}
            handleDocumentProcessing={indexDocument}
            isCompletedQuestionnaire
          />
        </>
      )}
      {uploadForm}
    </>
  )
}

export default UploadUserDocumentForm
